export default defineNuxtRouteMiddleware((to, from) => {
  const pageStore = usePageStore()

  if (to.meta.pageType === 'Product Page') {
    if (to.meta.pageType !== 'Product Page' || (to.meta.baseSku !== from.meta.baseSku)) {
      /**
       *
       * If the user is moving away from a product page or moving to a product page
       * which is a different model reset the store value that keeps the expanded
       * swatches open.
       *
       */
      pageStore.toggleShowAllLimitedEditionSwatches(false)
    }
  }

  return
})
