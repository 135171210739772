import dayjs from 'dayjs'
import getFrameTypes from '~/assets/js/constants/frameTypes'
import {
  TAG_PRESCRIPTION,
  TAG_EYEGLASSES,
} from '~/assets/js/constants/commerceTags'
import { mapLensTech } from '~/assets/js/constants/lensGuide'

export default function getterMixin(superclass) {
  return class CustomisedProduct extends superclass {
    get _hash() {
      return [
        this.state.currency,
        ...Object.values({
          ...this.state.choices,
          ...this.state.secondaryChoices,
          ck: this.state.conversionKitEnabled,
          ftoi: this.state.frameTypeOverrideIndex || 0,
          statefulSku: this.state.sku,
        }),
        ...Object.values(this._choices || {}).map(
          (choice) => choice?.activeProductOption?.id
        ),
      ].join('|')
    }

    get isLimitedEdition() {
      const IGNORE_PATTERNS = [
        /carebundle/,
        /lenscleankit/,
        /-ct_/
      ]

      if (IGNORE_PATTERNS.some(pattern => pattern.test(this.product.sku))) return false
      
      return this.product.isLimitedEdition || this.sku === this.product.sku
    }

    get hasLimitedEditionPart() {
      return Object.values(this.choices).some(
        (choice) => choice?.isLimitedEdition
      )
    }
    
    get baseSKU() {
      return this.product.baseSKU
    }

    get isPrescriptionSunglasses() {
      return /(le_STRX|le_STPRX|le_THRX|le_THPRX|le_RX)/
        .test(this.sku) &&
      !/(le_STCRXclear|le_THCRXclear|le_STBRXclear|le_THBRXclear|le_RXclear)/
        .test(this.sku)
    }

    get isEyeglasses() {
      return /(le_STCRXclear|le_THCRXclear|le_STBRXclear|le_THBRXclear|le_RXclear)/.test(this.sku)
    }

    get isPrescription() {
      return this.isPrescriptionSunglasses || this.isEyeglasses
    }

    get isSunglasses() {
      return !this.isPrescription
    }

    get isLifestyle() {
      return this.product.relatedProducts?.slug === 'lifestyle'
    }

    get isSnow() {
      return this.product.relatedProducts?.slug === 'snow'
    }

    get isPace() {
      return this.product.relatedProducts?.slug === 'pace'
    }

    get isCoreProduct() {
      return this.isSnow || this.isPace || this.isLifestyle
    }

    get isHeroDesign () {
      if (this.state.customised) return false
      return this.product.heroDesigns.some(design => design.normalisedSKU === this.normalisedSKU)
    }

    /**
     * PARTS and CHOICES
     */

    get partsWithChoice() {
      return this.product.parts.filter(
        (part) => part.stockManagedSKUPrefix in this.choices
      )
    }

    get visibleParts() {
      return this.product.parts.filter((part) => {
        if (
          !(part.stockManagedSKUPrefix in this.choices) &&
          !this.conversionKitEnabled &&
          !['parts', 'conversionkit'].includes(this.product.pageMode)
        )
          return false // Part has no primary choice
        if (part.followerOf && this.product.pageMode === 'custom') return false // Part is a follower of another part
        return true
      })
    }

    get choices() {
      return this.getMemoized('choices', () => {
        this._choices = Object.fromEntries(
          Object.entries(this.state.choices || {}).map(
            ([skuPrefix, optionSKU]) => {
              return [skuPrefix, this.product.getOptionBySKU(optionSKU)]
            }
          )
        )
        this._choices_hash = this._hash
        return this._choices
      })
    }

    get secondaryChoices() {
      return this.getMemoized('secondaryChoices', () => {
        return Object.fromEntries(
          Object.entries(this.state.secondaryChoices || {}).map(
            ([skuPrefix, optionSKU]) => {
              return [skuPrefix, this.product.getOptionBySKU(optionSKU)]
            }
          )
        )
      })
    }

    get allChoices() {
      return this.getMemoized('allChoices', () => {
        return {
          ...this.choices,
          ...this.secondaryChoices,
        }
      })
    }

    get allVisibleChoicesMade () {
      if (!this.isLimitedEdition) return true
      
      const expectedChoices = this.visibleParts.map(
        part => part.stockManagedSKUPrefix
      )
      const madeChoices = Object.keys(this.choices)
      return expectedChoices.every(choice => madeChoices.includes(choice))
    }

    get choicesForDisplay() {
      return this.getMemoized('choicesForDisplay', () => {
        const override = Object.values(this.choices)
          .map((choice) => choice.cartDetailsOverride)
          .flat()
          .filter((o) => o)
        if (override.length) return override

        return this.partsWithChoice
          .filter((part) => !part.excludeFromProductDetails)
          .map((part) => {
            const choice = this.choices[part.stockManagedSKUPrefix]

            if (this.allowLensTechFiltering && part.name === 'Lenses') {
              return [
                {
                  sku: part.sku,
                  partName: part.name,
                  partType: part.type,
                  effectName: choice?.effectName,
                  choiceName: choice?.name,
                  optionTechDisplayTitle: choice?.optionTech?.displayTitle,
                  optionTechCode: choice?.optionTech?.tech,
                },
                {
                  sku: part.sku,
                  partName: 'Colour',
                  partType: part.type,
                  effectName: choice?.effectName,
                  choiceName: choice?.name,
                },
              ]
            } else {
              return [
                {
                  sku: part.sku,
                  partName: part.name,
                  partType: part.type,
                  effectName: choice?.effectName,
                  choiceName: choice?.name,
                },
              ]
            }
          })
          .flat()
      })
    }

    get matchingPrebuiltVariant () {
      // Get prebuilt variants for this product and check to see if our current choices are all
      // present in the prebuilt variant and all choices are made
      const prebuiltVariants = this.product.prebuiltVariants
      return prebuiltVariants.find(variant => {
        // All current choices are present in the prebuilt variant
        const allChoicesMatch = Object.entries(this.choices).every(
          ([_skuPrefix, choice]) => variant.components.find(({ id }) => id === choice?.commerceId)
        )

        // All choices have been made
        const allChoicesMade = Object.keys(this.choices).length === variant.components.length

        return allChoicesMatch && allChoicesMade
      })
    }

    get isPrebuiltVariant () {
      /**
       * We should sell a prebuilt variant if:
       * 1. The customer hasn't customised the product
       * 2. This is explicitly a prebuilt variant, for example one that's already in cart
       * 3. We have a matching prebuilt variant for the current choices and;
       *    a. All choices have been made
       *    b. The prebuilt variant has a more favourable stock state than the its components (favour in stock over pre-order, pre-order over out of stock
       */

      // This is explicitly a prebuilt variant
      if (this.state.isPrebuiltVariant) return true

      // The customer hasn't customised the product
      if (this.state.customised) return false

      // We have a matching prebuilt variant
      const prebuiltVariant = this.matchingPrebuiltVariant
      if (!prebuiltVariant) return false

      // All choices have been made
      if (!this.allVisibleChoicesMade) return false

      // The prebuilt variant has a more favourable stock state than the its components
      const allChoices = Object.values(this.choices)
      const anyChoiceOnPreorder = allChoices.some(choice => choice.isOnPreorder)
      const allChoicesInStock = allChoices.every(choice => choice.isInStock)
      const variantOnPreorder = prebuiltVariant.isOnPreorder
      const variantOutOfStock = !prebuiltVariant.isInStock
      const variantInStock = prebuiltVariant.isInStock

      // If the variant is in stock then always sell the variant
      if (variantInStock) return true

      // If the variant is on preorder and components are in stock then sell the customised product
      if (variantOnPreorder && allChoicesInStock) return false

      // If the variant is on preorder and components are on preorder then sell the variant
      if (variantOnPreorder && anyChoiceOnPreorder) return true

      // If the variant is out of stock and not on preorder then sell the customised product
      if (variantOutOfStock && !variantOnPreorder) return false

      // Fallback to just selling the customised product
      return false
    }

    /**
     * LENSES
     */

    get lensPart() {
      return this.getMemoized('lensPart', () => {
        return this.product.parts.find((part) =>
          part.options.some((option) => option.partType === 'lenses')
        )
      })
    }

    get lensChoice() {
      return this.getMemoized('lensChoice', () => {
        if (this.product.lensChoiceOverride)
          return this.product.lensChoiceOverride
        return Object.values(this.choices).find(
          (option) => option?.partType === 'lenses'
        )
      })
    }

    get lensName() {
      if (this.product.lensNameOverride) return this.product.lensNameOverride
      return this.lensChoice?.name
    }

    get isIrisLens() {
      return this.sku.includes('le_8IR')
    }

    get isWaterLens() {
      return this.sku.includes('water')
    }
    
    get isCat4Lens() {
      return this.sku.includes('le_8chrome4')
    }

    get allowLensTechFiltering() {
      return this.product.allowLensTechFiltering
    }

    get lensTech() {
      if (this.product.staticLensTech?.tech)
        return mapLensTech(this.product.staticLensTech.tech)
      return mapLensTech(this.lensChoice?.lensTech)
    }

    get lensTechPrices() {
      if (!this.lensPart) return {}

      return this.lensPart.options.reduce((prices, option) => {
        return {
          ...prices,
          [option.lensTech]: option?.price,
        }
      }, {})
    }

    get lensTechPricesGBP() {
      if (!this.lensPart) return {}

      return this.lensPart.options.reduce((prices, option) => {
        return {
          ...prices,
          [option.lensTech]: option?.priceGBP,
        }
      }, {})
    }

    get lensTechSelected() {
      if (this.product.staticLensTech?.tech) return true
      return this.state.lensTechSelected
    }

    get isPrescriptionSunglassesLens() {
      return (
        /(le_STRX|le_STPRX|le_THRX|le_THPRX|le_RX)/
          .test(this.lensChoice?.sku) &&
        !/(le_STCRXclear|le_THCRXclear|le_RXclear|le_STBRXclear|le_THBRXclear)/
          .test(this.lensChoice?.sku)
      )
    }

    get isEyeglassesLens() {
      return /(le_STCRXclear|le_THCRXclear|le_STBRXclear|le_THBRXclear|le_RXclear)/
        .test(this.lensChoice?.sku)
    }

    get isPrescriptionSunglassesAvailable() {
      if (this.prescriptionSunglassesLenses.length) return true

      return false
    }

    get isEyeglassesAvailable() {
      if (this.eyeglassesLenses.length) return true

      return false
    }

    get prescriptionSunglassesLenses() {
      return this.lensOptionsByTag(TAG_PRESCRIPTION)
    }

    get eyeglassesLenses() {
      return this.lensOptionsByTag(TAG_EYEGLASSES)
    }

    get sunglassesLenses() {
      return this.lensOptionsByTag()
    }

    get baseProductWithToolTip() {
      return !!this.product.pdpConfig.buttonToolTipContent
    }

    get choiceWithToolTip() {
      return Object.values(this.choices).find(choice => choice.buttonToolTipTitle)
    }

    get hasButtonToolTip() {
      return this.baseProductWithToolTip || this.choiceWithToolTip
    }

    get hasLensTechOptionMaterial() {
      return !!(this.lensChoice?.optionTech.material || this.product.staticLensTech?.material)
    }

    get lensSpec() {
      if (!this.lensChoice?.vlt
        && !this.product.staticLensTech
        && !this.product.pdpConfig?.vlt
        && !this.product.baseProduct?.pdpConfig?.vlt) return null;

      return {
        material: this.lensChoice?.material
          || this.lensPart?.material
          || this.lensChoice?.optionTech?.material
          || this.product.staticLensTech?.material
          || null,
        vlt: this.lensChoice?.vlt || this.product.pdpConfig?.vlt || this.product.baseProduct?.pdpConfig?.vlt || null,
        abbe: this.lensChoice?.optionTech?.abbe || this.product.staticLensTech?.abbe || null,
        shape: this.product?.pdpConfig?.lensShape || this.product?.baseProduct?.pdpConfig?.lensShape || null,
        changeable: this.product?.pdpConfig?.lensChange || this.product?.baseProduct?.pdpConfig?.lensChange || 0
      }
    }

    /**
     * FRAMES
     */

    get isRecycled() {
      return this.sku.includes('_I')
    }

    get framePart() {
      return this.product.parts.find((part) =>
        part.options.some((option) => option.partType === 'frame')
      )
    }

    get frameChoice() {
      return Object.values(this.choices).find(
        (option) => option?.partType === 'frame'
      )
    }

    get frameTypes() {
      return this.getMemoized('frameTypes', () => {
        const frameTypes = getFrameTypes(this.baseSKU)

        if (this.isLimitedEdition)
          return frameTypes.map((frameType) => ({
            ...frameType,
            description: frameType.leDescription || frameType.description,
          }))

        return frameTypes
      })
    }

    get frameTypeDetails() {
      return this.getMemoized('frameTypeDetails', () => {
        if (this.frameTypes?.length === 1) return this.frameTypes[0]
        return this.frameTypes.find((frameType) =>
          frameType.parts?.some(
            (partSKUPrefix) => partSKUPrefix in this.choices
          )
        )
      })
    }

    get frameType() {
      return this.getMemoized('frameType', () => {
        if (this.product.frameTypeOverride) {
          if (this.conversionKitEnabled)
            return this.frameTypes[this.state.frameTypeOverrideIndex || 0]
              .frameType
          return this.product.frameTypeOverride
        }
        return this.frameTypeDetails?.frameType
      })
    }

    get frameTypeParts() {
      return this.frameTypes.find((ft) => ft.frameType === this.frameType).parts
    }

    get primarySwappableSKUPrefix() {
      return this.frameTypeParts.find((skuPrefix) =>
        this.product.parts.some(
          (part) => part.stockManagedSKUPrefix === skuPrefix
        )
      )
    }

    get secondarySwappableSKUPrefix() {
      const swappableEntry = this.product.swappableParts.find((swappable) =>
        swappable.includes(this.primarySwappableSKUPrefix)
      )
      return swappableEntry?.find(
        (partSKUPrefix) => partSKUPrefix !== this.primarySwappableSKUPrefix
      )
    }

    get alternateFrameType() {
      if (!this.frameType) return null
      return this.frameTypes.find(
        (option) => option.frameType !== this.frameType && !option.conversionKit
      )?.frameType
    }

    get conversionKitEnabled() {
      return this.state.conversionKitEnabled
    }

    get frameSpec() {
      const staticFrameSpecification = this.product.pdpConfig?.frameSpecification.length ? this.product.pdpConfig?.frameSpecification : this.product.baseProduct?.pdpConfig?.frameSpecification || null
      if (!this.framePart && !staticFrameSpecification) return null;
      if (!this.framePart && staticFrameSpecification) {
        return Object.fromEntries(
          staticFrameSpecification
            .filter(spec => spec?.title)
            .map(spec => [spec.title.toLowerCase(), spec])
        );
      };
    
      const additionalParts = this.visibleParts
        .filter(part => part.id !== this.framePart.id && part.specificationType === 'frames' && part.material);
    
      const transformedAdditionalParts = Object.fromEntries(
        additionalParts
          .map(part => part.material)
          .filter(spec => spec?.title)
          .map(spec => [spec.title.toLowerCase(), spec])
      );
    
      const transformedSpec = Object.fromEntries(
        Object.values(this.framePart?.additionalSpec || {})
          .filter(spec => spec?.title)
          .map(spec => [spec.title.toLowerCase(), spec])
      );
    
      const additionalSpecs = Object.fromEntries(
        additionalParts
          .flatMap(part => part.additionalSpec || [])
          .filter(spec => spec?.title)
          .map(spec => [spec.title.toLowerCase(), spec])
      );
    
      return {
        material: this.frameChoice?.material || this.framePart?.material || null,
        ...transformedAdditionalParts,
        ...transformedSpec,
        ...additionalSpecs,
        type: {
          title: 'Frame Type',
          value: this.frameTypeDetails?.title || this.frameTypeOverride || null
        }
      };
    }

    /**
     * ICONS
     */

    get iconsPart() {
      return this.getMemoized('iconsPart', () => {
        return this.product.parts.find((part) =>
          part.options.some((option) => option.partType === 'icon')
        )
      })
    }

    /**
     * MEASUREMENTS
     */

    get measurements() {
      if (!(this.product.pdpConfig?.weight || this.product.baseProduct?.pdpConfig?.weight)) return null;
      return {
        weight: this.product.pdpConfig?.weight || this.product.baseProduct.pdpConfig?.weight || null,
        lengthFrame: this.product?.pdpConfig?.lengthFrame || this.product.baseProduct?.pdpConfig?.lengthFrame || null,
        heightFrame: this.product?.pdpConfig?.heightFrame || this.product.baseProduct?.pdpConfig?.heightFrame || null,
        heightLenses: this.product?.pdpConfig?.heightLenses || this.product.baseProduct?.pdpConfig?.heightLenses || null,
        widthLenses: this.product?.pdpConfig?.widthLenses || this.product.baseProduct?.pdpConfig?.widthLenses || null
      }
    }

    /**
     * SKUs
     */

    _getSKUFromChoices(base, choices) {
      return this.product._getSKUFromChoices(base, choices)
    }

    _getSKUWithoutLensTech(base, choices) {
      return this.product.getSKUWithoutLensTech(base, choices)
    }

    _getLegacySKUFromChoices(base, choices) {
      return this.product._getLegacySKUFromChoices(base, choices)
    }

    _getNormalisedSKU(base, choices) {
      return this.product._getNormalisedSKU(base, choices)
    }

    _getAdFeedSKU(base, choices) {
      return this.product._getAdFeedSKU(base, choices)
    }

    get sku() {
      return this.getMemoized('sku', () => {
        return this._getSKUFromChoices(this.product.sku, this.choices)
      })
    }

    get skuWithoutLensTech() {
      return this.getMemoized('skuWithoutLensTech', () => {
        return this._getSKUWithoutLensTech(this.product.sku, this.choices)
      })
    }

    get secondarySKU() {
      return this.getMemoized('secondarySKU', () => {
        if (!this.product.pdpConfig?.secondaryProduct) return null

        return this._getSKUFromChoices(
          this.product.pdpConfig.secondaryProduct?.sku,
          this.secondaryChoices
        )
      })
    }

    get secondaryProductBaseSKU() {
      return this.product.pdpConfig.secondaryProduct?.sku
    }

    get normalisedSKU() {
      return this.getMemoized('normalisedSKU', () => {
        return this._getNormalisedSKU(this.product.sku, this.choices)
      })
    }

    get adFeedSKU() {
      return this.getMemoized('adFeedSKU', () => {
        return this._getAdFeedSKU(this.product.sku, this.choices)
      })
    }

    get legacySKU() {
      return this.getMemoized('legacySKU', () => {
        return this._getLegacySKUFromChoices(this.product.sku, this.choices)
      })
    }

    get secondaryLegacySKU() {
      return this.getMemoized('secondaryLegacySKU', () => {
        return this._getLegacySKUFromChoices(
          this.product.pdpConfig.secondaryProduct?.sku,
          this.secondaryChoices
        )
      })
    }

    get extraSKUs() {
      if (this.product.pageMode !== 'parts') return []

      const partType = Object.values(this.choices)?.[0]?.partType
      const productSKU = this.product.sku

      const mapping = {
        vanguardsparts: {
          frame: ['ge_box', 'ge_outerbox', 'ge_microfiber'],
          lenses: ['ge_lensbox', 'ge_outerlensbox', 'ge_microfiber'],
        },
        ullrsparts: {
          frame: ['ge_box', 'ge_outerbox', 'ge_microfiber'],
          lenses: ['ge_lensbox', 'ge_outerlensbox', 'ge_microfiber'],
        },
        revoltparts: {
          frame: ['ge_box', 'ge_outerbox', 'ge_microfiber'],
          lenses: ['ge_lensbox', 'ge_outerlensbox', 'ge_microfiber'],
        },
        snipersparts: {
          frame: ['ge_box', 'ge_outerbox', 'ge_microfiber'],
          lenses: ['ge_lensbox', 'ge_outerlensbox', 'ge_microfiber'],
        },
      }

      if (!(productSKU in mapping)) return []
      return mapping[productSKU][partType] || []
    }

    get skuWithExtras() {
      return [this.sku, ...this.extraSKUs].join('-')
    }

    get additionalProductSKU() {
      const productAdditionalProduct =
        this.product?.pdpConfig?.additionalProductSKU ||
        this.product?.baseProduct?.pdpConfig?.additionalProductSKU

      const partAdditionalProduct = Object.values(this.choices).find(
        (option) => option.additionalProductSKU
      )?.additionalProductSKU
      return productAdditionalProduct || partAdditionalProduct
    }

    /**
     * PRICE
     */

    get price() {
      function sumChoices(choices) {
        return Object.values(choices).reduce((sum, option) => {
          return sum + (option?.price || 0)
        }, 0)
      }

      if (this.isPrebuiltVariant) {
        const prebuiltVariant = this.matchingPrebuiltVariant
        return prebuiltVariant.price
      }

      // If the product doesn't have a commerceId then we can't have conficence in the price.
      // We should hide the price instead of showing 'free'
      if (!this.product.commerceId) return null

      let price = this.product.basePrice + sumChoices(this.choices)

      if (this.conversionKitEnabled) {
        price += sumChoices(this.secondaryChoices)
      }

      return price
    }

    get priceGBP() {
      function sumChoices(choices) {
        return Object.values(choices).reduce((sum, option) => {
          return sum + (option?.priceGBP || 0)
        }, 0)
      }

      if (this.isPrebuiltVariant) {
        const prebuiltVariant = this.matchingPrebuiltVariant
        return prebuiltVariant.prices?.['GBP'] || 0
      }

      let price = this.product.basePriceGBP + sumChoices(this.choices)

      if (this.conversionKitEnabled) {
        price += sumChoices(this.secondaryChoices)
      }

      return price
    }

    get compareAtPrice() {
      return this.getMemoized('compareAtPrice', () => {
        function sumChoices(choices) {
          return Object.values(choices).reduce((sum, option) => {
            return sum + (option?.compareAtPrice || 0)
          }, 0)
        }

        if (this.isPrebuiltVariant) {
          const prebuiltVariant = this.matchingPrebuiltVariant
          return prebuiltVariant.compareAtPrice || prebuiltVariant.price
        }

        let price = this.product.compareAtPrice + sumChoices(this.choices)

        if (this.conversionKitEnabled) {
          price += sumChoices(this.secondaryChoices)
        }

        return price
      })
    }

    get showMinimumPrice() {
      return this.allowLensTechFiltering && !this.lensTechSelected
    }

    get minimumPrice() {
      return this.getMemoized('minimumPrice', () => {
        return this.partsWithChoice
          .map((part) => {
            const choice = this.choices[part.stockManagedSKUPrefix]
            if (!choice) return 0

            if (choice.partType === 'lenses') {
              if (this.isPrescriptionSunglasses) {
                return part.minimumPrescriptionPrice
              } else if (this.isEyeglasses) {
                return part.minimumEyeglassesPrice
              }

              return part.minimumPrice
            } else {
              return choice?.price
            }
          })
          .reduce((sum, val) => sum + val, 0)
      })
    }

    get minimumCompareAtPrice() {
      return this.getMemoized('minimumCompareAtPrice', () => {
        return this.partsWithChoice
          .map((part) => {
            const choice = this.choices[part.stockManagedSKUPrefix]
            if (!choice) return 0
            if (choice.partType === 'lenses') return part.minimumCompareAtPrice
            return choice?.compareAtPrice
          })
          .reduce((sum, val) => sum + val, 0)
      })
    }

    getMinimumPartPrice(skuPrefix) {
      const part = this.product.parts.find(
        (part) =>
          part.skuPrefix === skuPrefix ||
          part.stockManagedSKUPrefix === skuPrefix
      )
      return part?.minimumPrice
    }

    /**
     * DELIVERY
     */

    get isInStock() {
      if (this.isPrebuiltVariant) return this.matchingPrebuiltVariant.isInStock

      return (
        this.product.isInStock &&
        Object.values(this.choices).every(
          (choice) => choice?.isInStock || choice?.isOnPreorder
        )
      )
    }

    get isOnPreorder() {
      return this.preorderPartSelected
    }

    get preorderPartSelected() {
      if (this.isPrebuiltVariant) return this.matchingPrebuiltVariant.isOnPreorder

      return (
        (Object.values(this.choices).some((choice) => choice?.isOnPreorder) ||
          this.product?.isOnPreorder) ??
        false
      )
    }

    get allPreorderPartsAsDelayedDespatch () {
      return [
        !this.product.isOnPreorder || this.product.preorderAsDelayedDespatch,
        ...Object.values(this.choices).map(
          (choice) => !choice?.isOnPreorder || choice?.preorderAsDelayedDespatch
        )
      ].every(Boolean)
    }

    get preorderDate() {
      return [
        this.product.inboundDeliveryDate,
        ...Object.values(this.choices).map(
          (choice) => choice.isOnPreorder && choice.inboundDeliveryDate
        ),
      ]
        .filter(
          (date) => date // Most of these will be false
        )
        .sort(
          (a, b) => dayjs(b).valueOf() - dayjs(a).valueOf() // Sort by date
        )?.[0] // Select the latest date
    }

    get delayedDespatchDate() {
      const delayedDespatchDate = [
        this.product.isDelayedDespatch && this.product.delayedDespatchDate,
        ...Object.values(this.choices).map(
          (choice) => choice?.isDelayedDespatch && choice?.delayedDespatchDate
        ),
      ]
        .map((date) => {
          if (!date) return false
          if (typeof date === 'string') return new Date(date)
          if (typeof date === 'number') return new Date(date * 1000)
          return date
        })
        .filter((date) => date && date > new Date())
        .sort(
          (a, b) => dayjs(b).valueOf() - dayjs(a).valueOf() // Sort by date
        )?.[0] // Select the latest date

      // Return as a date string eg 2024-11-27
      return delayedDespatchDate ? delayedDespatchDate.toISOString().split('T')[0] : null
    }

    /**
     * DISPLAY
     */

    get productNameOverride() {
      return Object.values(this.choices)
        .map((choice) => choice?.productNameOverride)
        .filter((str) => str)?.[0]
    }

    get productName() {
      if (
        this.isLimitedEdition &&
        !this.product.useBaseProductName &&
        !this.product.name.match(/[³|™]/g)
      ) {
        return this.product.name + '&nbsp;'
      }

      if (this.isLimitedEdition) {
        return this.product.useBaseProductName && this.product.baseProduct?.name
          ? this.product.baseProduct.name
          : this.product.name
      }

      return this.product.baseProduct?.name || this.product.name
    }

    get name() {
      return this.getMemoized('name', () => {
        // If it's a parts kit return the base name
        if (this.product.pageMode === 'parts') return this.baseName

        let name = this.product.name.trim()

        // Check for any product name overrides (eg cases)
        name = this.productNameOverride || name

        // Fix case of McLaren
        name = name.replace(
          'McLaren',
          '<span class="normal-case">Mc</span>Laren'
        )

        // Strip SS and LE
        name = name
          .replace(/Signature Series/gi, '')
          .replace(/Limited Edition/gi, '')

        // If this is a limited edition product then strip the base product name
        if (this.isLimitedEdition) {
          name = name.replace(
            /Vanguards|Ullrs|Revolts|Snipers|Classics|Sierras|Renegades|Vulcans|GTs|Velans|Snipers|Ultras|Airas|Tempests|Zephyrs|Tokas|Miras|³|™/gi,
            ''
          )

          if (name?.trim() !== this.baseName?.trim())
            name = name.replace(this.baseName, '')
        }

        // Add frame type suffix
        if (this.frameType && this.frameTypes.length > 1)
          name += ` ${this.frameType.toUpperCase()}`

        return name.trim()
      })
    }

    get secondaryName() {
      return this.getMemoized('secondaryName', () => {
        return [
          this.product.pdpConfig.secondaryProduct.name.trim(),
          this.product.pdpConfig.secondaryProduct.subTitle?.trim(),
        ]
          .filter((str) => str)
          .join(' ')
      })
    }

    get nameWithCK() {
      if (!this.conversionKitEnabled || !this.alternateFrameType)
        return this.name
      return `${this.name} & ${this.alternateFrameType.toUpperCase()}`
    }

    get baseName() {
      if (this.sku.startsWith('microfib')) return null

      return this.product.baseProduct?.name || this.product.name
    }

    get subTitle() {
      if (this.product.pageMode === 'parts') {
        const partType = Object.values(this.choices)?.[0]?.partType
        if (partType === 'lenses') return 'Lens Kit'
        if (partType === 'frame') return 'Frame Kit'
        if (partType === 'strap') return 'Strap Kit'
      }

      return this.product?.subTitle
    }

    get limitedEditionDescription() {
      return this.product.pdpConfig?.limitedEditionDescription
    }

    get metaDescription() {
      return (
        this.product.pdpConfig?.meta?.find(
          (row) => row.attributes?.name === 'description'
        )?.attributes?.content || ''
      )
    }

    get swatches() {
      return [this.frameChoice?.swatch, this.lensChoice?.swatch]
    }

    get swatchIconOverride() {
      return this.state.swatchIconOverride || this.product.swatchIconOverride
    }

    get url() {
      if (this.isLimitedEdition) return this.product.url
      return `${this.product.url}?id=${this.sku}`
    }

    get prescriptionHeroDesigns() {
      return this.product.prescriptionHeroDesigns.filter(
        (design) => !design.isLimitedEdition
      )
    }

    get eyeglassesHeroDesigns() {
      return this.product.eyeglassesHeroDesigns.filter(
        (design) => !design.isLimitedEdition
      )
    }

    get heroDesigns() {
      return this.product.heroDesigns
    }

    get convincerBlocks() {
      return this.product.pdpConfig.convincerBlocks
    }

    get lifestyleImages() {
      // Define a helper method for stripping lens tech from a SKU
      const stripLensTech = (sku) => (sku || '').replace(/_(P|N|8P|8|RX)/g, '_')

      // Get all lifestyle images from the product
      const images = (this.product.pdpConfig.lifestyleImages || []).map(
        (image) => ({
          ...image,
          optionSku1: stripLensTech(image.optionSku1),
          optionSku2: stripLensTech(image.optionSku2),
        })
      )

      // Strip lens tech from the legacy SKU
      const legacySKUWithoutLensTech = stripLensTech(this.legacySKU)

      // Score each against the current design and sort them
      const sortedImages = images
        .map((image) => {
          let score = 0
          if (image.optionSku1 && legacySKUWithoutLensTech.includes(image.optionSku1)) score += 2
          if (image.optionSku2 && legacySKUWithoutLensTech.includes(image.optionSku2)) score += 1
          return { ...image, score }
        })
        .sort((a, b) => b.score - a.score)

      return sortedImages
        .slice(0, 2)
        .sort((a, b) => parseInt(b.id) - parseInt(a.id))
        .map((image) => image.image)
    }

    /**
     *
     * Returns the available lens options for a product which can be filtered by a tag.
     *
     * This method does not require a lens choice to have been made beforehand.
     *
     */
    lensOptionsByTag(tagFilter) {
      const excludedPricing = this.lensChoice?.price || 0
      const excludedPricingGBP = this.lensChoice?.priceGBP || 0

      const basePrice = this.price - excludedPricing
      const basePriceGBP = this.priceGBP - excludedPricingGBP

      let relatedVariants = Object.values(this.product.allOptions)
        .filter((option) => {
          if (tagFilter) {
            return (
              option.partType === 'lenses' &&
              option.tags.find((tag) => tag.value === tagFilter)
            )
          } else {
            // if no tag filter just return standard lenses by filtering our anything wuth a tag value
            return option.partType === 'lenses' && !option.tags.length
          }
        })
        .map((option) => {
          return option.hasProductOptions ? option.productOptions : option
        })
        .flat()

      relatedVariants = relatedVariants.filter((variant, index, self) => {
        return self.findIndex((v) => v.id === variant.id) === index
      })

      const lensOptions = relatedVariants
        .map((variant) => {
          const { productOptionId } = variant

          const preorder = variant?.isOnPreorder
          const inStock = variant?.isInStock
          const price = this.isLimitedEdition
            ? null
            : variant.amountFloat + basePrice
          const priceGBP =  this.isLimitedEdition
            ? null
            : variant.amountFloatGBP + basePriceGBP
          const priceDifference = price - this.price
          const disabled = !inStock && !preorder

          return {
            ...variant._rawData,
            ...variant.optionTech,
            productOptionId,
            disabled,
            preorder,
            price,
            priceGBP,
            priceDifference,
            inStock,
          }
        })
        .filter((option) => option.price)

      return lensOptions
    }

    /**
     *
     * This returns all variants of a lens colour with lens tech for the currently selected lens colour
     *
     * For example: le_smoke = le_Nsmoke, le_Psmoke, le_8smoke, le_8Psmoke, le_RXsmoke
     *
     */
    get lensOptions() {
      if (!this.lensChoice) return []

      const excludedPricing = this.lensChoice?.price || 0
      const excludedPricingGBP = this.lensChoice?.priceGBP || 0

      const basePrice = this.price - excludedPricing
      const basePriceGBP = this.priceGBP - excludedPricingGBP

      const skuMatcher = this.lensChoice.skuWithoutLensTech

      let relatedVariants = Object.values(this.product.allOptions)
        .filter((option) => {
          return (
            option.partType === 'lenses' &&
            option.skuWithoutLensTech === skuMatcher
          )
        })
        .map((option) =>
          option.hasProductOptions ? option.productOptions : option
        )
        .flat()

      // Deduplicate relatedVariants
      relatedVariants = relatedVariants.filter((variant, index, self) => {
        return self.findIndex((v) => v.id === variant.id) === index
      })

      const lensOptions = relatedVariants
        .map((variant) => {
          const { productOptionId } = variant

          const preorder = variant?.isOnPreorder
          const inStock = variant?.isInStock
          const price = this.isLimitedEdition
            ? null
            : variant.amountFloat + basePrice
          const priceGBP = this.isLimitedEdition
            ? null
            : variant.amountFloatGBP + basePriceGBP
          const priceDifference = price - this.price
          const disabled = !inStock && !preorder

          return {
            ...variant._rawData,
            ...variant.optionTech,
            productOptionId,
            disabled,
            preorder,
            price,
            priceGBP,
            priceDifference,
            inStock,
          }
        })
        .filter((option) => option.price)

      return lensOptions
    }

    get lensTechOptions() {
      if (!this.lensChoice) return []

      return this.lensOptions.filter((variant) => {
        return ['4ko', '8ko', '4kosnow', '8kosnow'].includes(
          mapLensTech(variant.tech)
        )
      })
    }

    /**
     *
     * lensTypeOptions returns the different options available for a selected
     * lens tech.
     *
     * It requires a lens tech to have been set before accessing.
     *
     * If you want all lensOptions for a product simply access lensOptions.
     *
     */
    get lensTypeOptions() {
      if (!this.lensChoice) return []

      return this.lensOptions.filter((lensOption) => {
        if (
          !this.isPrescriptionSunglasses &&
          (!lensOption.optionTech || !this.lensChoice?.optionTech)
        )
          return false
        return lensOption.optionTech.tech.includes(
          this.lensChoice.lensTech.replace(/p$/, '')
        )
      })
    }

    get productCategoryOptions() {
      return this.product.categories.map((category) => {
        const contentFilter = category.contentFilter
        const hasFilter = contentFilter !== null

        let filteredLensOptions = hasFilter
          ? this.lensOptions.filter((lens) => {
              return lens.tags.some((tag) => tag.value === contentFilter.value)
            })
          : this.lensOptions

        // If lenses haven't been tagged, return the full list to prevent a blank category
        if (!filteredLensOptions.length && hasFilter) {
          filteredLensOptions = this.lensOptions
        }

        return {
          ...category,
          minPrice: filteredLensOptions.length
            ? Math.min(
                ...filteredLensOptions.map((option) =>
                  option.price !== null ? option.price : 0
                )
              )
            : null,
        }
      })
    }

    get lensKitUpsellConfig() {
      const lensKitUpsell = this.product.upsells.find(
        (upsell) => upsell.mode === 'lens_kit'
      )

      // Don't offer LRKs for prescription products
      if (this.isPrescription) return null

      return lensKitUpsell
    }

    get caseUpsellConfig() {
      return this.product.upsells.find(
        (upsell) => upsell.mode === 'capsule_case'
      )
    }

    get compactCaseUpsellConfig() {
      return this.product.upsells.find(
        (upsell) => upsell.mode === 'compact_case'
      )
    }

    get conversionKitUpsellConfig() {
      return this.product.upsells.find(
        (upsell) => upsell.mode === 'conversion_kit'
      )
    }

    get strapUpsellConfig() {
      return this.product.upsells.find((upsell) => upsell.mode === 'strap_kit')
    }

    get simpleUpsellConfig() {
      return this.product.upsells.filter((upsell) => upsell.mode === 'simple')
    }

    get simpleMultipleUpsellConfig() {
      return this.product.upsells.filter(
        (upsell) => upsell.mode === 'simple_multiple'
      )
    }

    get upsellItems() {
      if (this.isLimitedEdition)
        return {
          case: this.caseUpsellConfig,
          compactCase: this.compactCaseUpsellConfig,
          ...Object.fromEntries(
            this.simpleUpsellConfig.map((item) => [item.key, item])
          ),
        }

      return {
        case: this.caseUpsellConfig,
        compactCase: this.compactCaseUpsellConfig,
        lensKit: this.lensKitUpsellConfig,
        conversionKit: this.conversionKitUpsellConfig,
        strap: this.strapUpsellConfig,
        ...Object.fromEntries(
          this.simpleUpsellConfig.map((item) => [item.key, item])
        ),
        ...Object.fromEntries(
          this.simpleMultipleUpsellConfig.map((item) => [item.key, item])
        ),
      }
    }

    /**
     *
     * Returns an array of commerce ids for all parts
     *
     */
    get commerceIds() {
      if (this.isLimitedEdition) {
        return [ this.product.commerceId.split('/').pop() || '' ]
      }

      if(this.isPrebuiltVariant) {
        return this.matchingPrebuiltVariant?.commerceId?.split('/')?.pop() || ''
      } else {
        if (Object.entries(this.choices).length) {
          return (Object.values(this.choices).map(choice => {
            return choice?.commerceId?.split('/')?.pop() || ''
          }))
        }
      }

      return []
    }
  }
}
