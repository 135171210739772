export default {
  id: 'bg',
  name: 'Background',
  isBackground: true,
  hasOptions: false,
  imgproxySettings: [
    'insecure',
    'resize:fill:1400:1400',
    'plain'
  ],
  mobileImgproxySettings: [
    'insecure',
    'resize:fill:800:800',
    'plain'
  ],
  pathTemplate: null // Intentionally removed
}