import { DEFAULT_PAGE_THEME, PAGE_THEMES } from '~/assets/js/constants/pageThemes'
import { isValidPageTheme } from '~/assets/js/helpers/pageThemes'

export const usePageStore = defineStore('page', {
  state: () => {
    return {
      hidePageFooter: false,
      cookieStatus: null,
      showCookieModal: false,
      localeCheckComplete: false,
      mobileMenuOpen: false,
      preventBodyScroll: false,
      showTopSpacer: false,
      allowLoadingSpinner: true,
      pageHistory: [],
      emailModalShown: false,
      pageTheme: DEFAULT_PAGE_THEME,
      showAllLimitedEditionSwatches: false
    }
  },

  actions: {
    togglePageFooter(value) {
      this.hidePageFooter = value
    },
    setCookieStatus(cookieStatus) {
      this.cookieStatus = cookieStatus
    },
    toggleCookieModal(show) {
      this.showCookieModal = show
    },
    setLocaleCheckState (complete) {
      this.localeCheckComplete = complete
    },
    toggleMobileMenuOpen(show) {
      this.mobileMenuOpen = show
    },
    toggleBlockBodyScroll(block) {
      this.preventBodyScroll = block
    },
    setShowTopSpacer (show) {
      this.showTopSpacer = show
    },
    toggleAllowLoadingSpinner(allow) {
      this.allowLoadingSpinner = allow
    },
    updatePageHistory(page) {
      // Set current page history if it's not already set
      if (
        this.pageHistory.length === 0 ||
        this.pageHistory[0].path !== page.path
      ) {
        this.pageHistory.unshift(page)
      }

      // Set previous page history
      if (this.pageHistory.length > 1) {
        this.previousPageHistory = this.pageHistory[1]
      }

      // Keep latest 5 pages in history
      if (this.pageHistory.length > 5) {
        this.pageHistory.pop()
      }
    },
    updateEmailModalStatus(emailModalStatus) {
      this.emailModalShown = emailModalStatus
    },
    setPageTheme (theme) {
      this.pageTheme =
        isValidPageTheme(theme) ? PAGE_THEMES[theme] : DEFAULT_PAGE_THEME
    },
    toggleShowAllLimitedEditionSwatches(value) {
      this.showAllLimitedEditionSwatches = value
    }
  },

  persist: {
    storage: piniaPluginPersistedstate.localStorage(),
    pick: ['cookieStatus', 'localeCheckComplete']
  }
})
