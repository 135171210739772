export default ra => ({
  async sort (params) {
    await ra.track('Filter results sorted', {
      sortedBy: params.sortedBy,
      numberOfOptions: params.numberOfOptions
    })
  },

  async resultsLoaded (params) {
    await ra.track('Filter results loaded', {
      totalResults: params.totalResults,
      totalPages: params.totalPages,
      pageNumber: params.pageNumber
    })
  },

  async resultClicked (params) {
    await ra.track('Filter result clicked', {
      resultElementClicked: params.resultElementClicked,
      numberInList: params.numberInList,
    })
  },

  async clearFilter (params) {
    await ra.track('Clear filter', {
      filterText: params.filterText,
    })
  },

  async clearAllFilters () {
    await ra.track('Clear all filters')
  }
})
