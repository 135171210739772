export default defineNuxtPlugin(() => {
  const { $product } = useNuxtApp()

  function getPart(customisedProduct, upsellProduct, key, upsellItem) {
    if (upsellItem.mode === 'simple' || key === 'case') return null
    
    if (key === 'lensKit') {
      return upsellProduct.product.parts.find(
        (part) => part.type === 'lenses'
      )
    }

    if (key === 'strap') {
      return upsellProduct.product.parts.find(
        (part) => part.type === 'strap'
      )
    }

    if (key === 'conversionKit') {
      const activeSKUPrefix = customisedProduct.secondarySwappableSKUPrefix
      return upsellProduct.product.parts.find(
        (part) => part.stockManagedSKUPrefix === activeSKUPrefix
      )
    }

    return null
  }

  function getAvailableOptions(customisedProduct, part) {
    if (!part) return null

    // Filter out options that are already selected or out of stock
    const filteredOptions = part
      .getFilteredOptions(customisedProduct)
      .filter(
        (option) =>
          (option.isInStock || option.doNotTrackStock) &&
          !Object.values(customisedProduct.state.choices).includes(option.sku)
      )

    return filteredOptions
  }

  function setInitialOption(
    customisedProduct,
    upsellProduct,
    upsellItem,
    availableOptions
  ) {
    if (!availableOptions?.length) return

    let initialOption = availableOptions[0]

    if (upsellItem.matchColourTo) {
      // Attempt to match the colour of the CK upsell to the main product
      const colourPart = customisedProduct.choices[upsellItem.matchColourTo]
      if (colourPart) {
        initialOption =
          availableOptions.find(
            (o) => o.sku.split('_')[1] === colourPart.sku.split('_')[1]
          ) || initialOption
      }
    }

    if (!initialOption) return

    upsellProduct.setChoice(initialOption.sku)

    // Handle product options, eg Prescription
    if (initialOption.hasProductOptions) {
      const activeProductOption =
        customisedProduct.lensChoice?.activeProductOption ||
        customisedProduct.lensChoice?.productOptions?.[0]
      if (activeProductOption)
        initialOption.setActiveProductOption(activeProductOption.id)
    }
  }

  function validateUpsell(upsellItem) {
    if (!upsellItem || !upsellItem.baseSKU) return false

    if (upsellItem.mode === 'simple' || upsellItem.mode === 'capsule_case' || upsellItem.mode === 'compact_case') {
      return upsellItem.product.isInStock || upsellItem.product.doNotTrackStock
    } else if (upsellItem.mode === 'simple_multiple') {
      return upsellItem.products.some(
        cp => cp.product.isInStock || cp.product.doNotTrackStock
      )
    } else {
      return upsellItem.availableOptions?.length
    }
  }

  const getUpsellsForProduct = async (customisedProduct) => {
    // Get all upsell base SKUs and fetch the product data of all in a single request
    const upsellBaseSKUs = Object.values(customisedProduct.upsellItems)
      .map((item) => item?.baseSKU)
      .filter((item) => !!item)

    if (!upsellBaseSKUs.length) return []
    
    await $product.get(upsellBaseSKUs)

    const upsellItems = Object.entries(customisedProduct.upsellItems).map(
      async ([key, upsellItem]) => {
        if (!upsellItem) return

        let product
        try {
          if (key === 'case') {
            product = await $product.getCustomisedProduct(upsellItem.sku, {
              sku: upsellItem.sku,
            })
          } else if (key === 'compactCase') {
            product = await $product.getCustomisedProduct(upsellItem.sku, {
              sku: upsellItem.sku,
            })
          } else if (['lens_kit', 'strap_kit'].includes(upsellItem.mode)) {
            product = await $product.getCustomisedProduct(
              upsellItem.baseSKU
            )
          } else if (key === 'conversionKit') {
            if (customisedProduct.conversionKitEnabled) return // Don't offer conversion kits if they're already enabled
            product = await $product.getCustomisedProduct(
              upsellItem.baseSKU
            )
          } else if (upsellItem.mode === 'simple') {
            product = await $product.getCustomisedProduct(
              upsellItem.baseSKU,
              { sku: upsellItem.upsellSKU }
            )
          } else if (upsellItem.mode === 'simple_multiple') {
            upsellItem.products = (await Promise.all(
              upsellItem.upsellProducts.map(async (product) => {
                return await $product.getCustomisedProduct(
                  product.sku
                )
              })
            )).filter(cp => cp.product.isInStock || cp.product.doNotTrackStock)
            upsellItem.product = upsellItem.products?.[0] || null
          }
        } catch (e) {
          console.log(`Unable to fetch upsell product for ${key}`)
          console.error(e)
          return
        }

        if (product) {
          const part = getPart(customisedProduct, product, key, upsellItem)
          const availableOptions = getAvailableOptions(customisedProduct, part)
          setInitialOption(
            customisedProduct,
            product,
            upsellItem,
            availableOptions
          )

          upsellItem.product = product
          upsellItem.part = part
          upsellItem.availableOptions = availableOptions
          upsellItem.isInCart = false
        }
        
        return upsellItem
      }
    )

    const unfilteredUpsells = await Promise.all(upsellItems)
    return unfilteredUpsells.filter(validateUpsell)
  }

  return {
    provide: {
      upsell: {
        getUpsellsForProduct
      }
    }
  }
})
