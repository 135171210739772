import {
  mediaFragment
} from './mediaFragments.gql'

export const titleBlockFragment = `
  text
  textSize
  tag
  style
`

export const singleImageRecordFragment = `
  ... on SingleImageRecord {
    __typename
    _modelApiKey
    id
    ${mediaFragment('image', '{ w: "600", fit: clip, q: 95 }')}
    ${mediaFragment('mobileImage', '{ w: "400", fit: clip }')}
    maxWidth
    minWidth
    widthMode
  }
`

export const textWithBackgroundFragment = `
  ... on TextWithBackgroundRecord {
    __typename
    _modelApiKey
    id
    title
    subtitle
    copy
    gradientOverlay
    ${mediaFragment('background', '{ w: "600", fit: clip }')}
    ${mediaFragment('mobileBackground', '{ w: "400", fit: clip }')}
  }
`

export const highlighterHeadingRecordFragment = `
  ... on HighlighterHeadingRecord {
    __typename
    _modelApiKey
    id
    highlightType
    size
    heading {
      value
    }
  }
`

export const customThemeFragment = `
  ... on CustomThemeRecord {
    id
    type: themeType
    buttonBackgroundGradient
    buttonBackgroundColour {
      hex
    }
    buttonTextColour {
      hex
    }
  }
`

export const inlineCTARecordFragment = `
  ... on InlineCtaRecord {
    id
    __typename
    copy
    style
    target
    trackingId
    buttonTheme {
      ${customThemeFragment}
    }
  }
`

export const articleVideoRecordFragment = `
  ... on ArticleVideoRecord {
    __typename
    _modelApiKey
    id
    video {
      url
      thumbnailUrl
      height
      width
      provider
      providerUid
      title
    }
  }
`

export const structuredTextFragment = `
  value
  blocks {
    ${singleImageRecordFragment}
    ${highlighterHeadingRecordFragment}
    ${inlineCTARecordFragment}
    ${articleVideoRecordFragment}
    ... on TitleBlockRecord {
      id
      __typename
      _modelApiKey
      ${titleBlockFragment}
    }
  }
  links
`

export const genericStructuredTextFragment = `
  copy {
    ${structuredTextFragment}
  }
  textColour
  textAlign
  mobileTextAlign
  blockAlign
  mobileBlockAlign
`