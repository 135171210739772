export const imgproxySettings = [
  'insecure',
  'crop:2100:1500',
  'resize:fill:1400',
  'plain'
]

export const mobileImgproxySettings = [
  'insecure',
  'crop:2100:1500',
  'resize:fill:800',
  'plain'
]

export function stripMatteOrInfinitePrefix (sku) {
  // These SKUs start with a capital I or a capital M but render
  // configs are agnostic so don't have the prefix
  if (!sku) return sku
  return sku.replace(/^[IM]/, '')
}

export function resolveLifestyleSelectedOptions (customisedProduct) {
  const engraving = {
    '4ko': 'eN',
    '4kop': 'eP',
    '8ko': 'e8',
    '8kop': 'e8P'
  }[customisedProduct.lensTech] || 'none'

  return {
    frame: stripMatteOrInfinitePrefix(customisedProduct.frameChoice?.skuWithoutPrefix),
    lens: customisedProduct.lensChoice.skuWithoutLensTech.split('_')?.[1],
    icons: customisedProduct.state.choices.i?.split('_')?.[1],
    engraving,
    reflections: 'r5pc'
  }
}

export const lifestyleLensTechRemovalPattern = /^(P|N|8P|8|[A-Z]{0,3}RX)/g

export function resolveLifestyleOptionsFromSKU (sku) {
  const frameSKUPrefixes = ['c3f', 'c4f', 'sf', 'rgf', 'tf', 'zf', 'tof', 'mf']
  const lensSKUPrefixes = ['c3le', 'sle', 'rgle', 'tle', 'zle', 'tole', 'mle']
  const iconSKUPrefixes = ['c3i', 'si', 'rgi', 'ti', 'i']
  const logoSKUPrefixes = ['zll', 'toll', 'mll', 'll']

  const getEngraving = (choice) => {
    const engravings = [
      [/^N/g, 'eN'],
      [/^P/g, 'eP'],
      [/^8(?!P|IR)/g, 'e8'],
      [/^8P/g, 'e8P']
    ]
    return engravings.find(([pattern, _option]) => choice.match(pattern))?.[1] || 'none'
  }

  const parts = sku.split('-').slice(1)
  return Object.fromEntries(parts.map((part) => {
    const [skuPrefix, choice] = part.split('_')
    if (frameSKUPrefixes.includes(skuPrefix)) return [['frame', stripMatteOrInfinitePrefix(choice)]]
    if (lensSKUPrefixes.includes(skuPrefix)) return [
      ['lens', choice.replace(lifestyleLensTechRemovalPattern, '')],
      ['engraving', getEngraving(choice)],
      ['reflections', 'r5pc']
    ]
    if (iconSKUPrefixes.includes(skuPrefix)) return [['icons', choice]]
    if (logoSKUPrefixes.includes(skuPrefix)) return [['logos', choice]]

    return null
  }).filter(choice => choice).flat())
}

export function resolvePaceOptionsFromSKU (sku) {
  const frameSKUPrefixes = ['vcf', 'vef', 'af', 'uf', 'gtf']
  const bottomSKUPrefixes = ['vcb', 'veb', 'ab', 'cb', 'sb']
  const noseSKUPrefixes = ['vcn', 'ven', 'an', 'un', 'cn', 'sn']
  const lensSKUPrefixes = ['vcle', 'vele', 'ale', 'ule', 'gtle']
  const iconSKUPrefixes = ['vci', 'vei', 'ai', 'ui', 'gti', 'i']
  const earsockSKUPrefixes = ['vces', 'vees', 'aes', 'ues', 'ges', 'es']

  let frameType = [
    [/ultras-/, ''],
    [/airas.*-ab_|airas.*-cb_/, 'BF'],
    [/airas.*-an_|airas.*-cn_/, 'ZF'],
    [/-veb_|-vcb_|-cb_|-sb_/, 'FF'],
    [/-ven_|-vcn_|-cn_|-sn_/, 'TF'],
    [/-uf_/, ''],
    [/-gtf_/, '']
  ].find(([pattern, _]) => sku.match(pattern))?.[1]

  // If frameType is undefined then this is likely an LRK, use the default for each product
  if (!frameType) {
    const baseSKU = sku.split('-')[0]
    frameType = {
      airaslrk: 'ZF',
      vulcanslrk: 'FF',
      velanslrk: 'TF',
      ultraslrk: ''
    }[baseSKU] || ''
  }

  const parts = sku.split('-').slice(1)
  return Object.fromEntries(parts.map((part) => {
    const [skuPrefix, choice] = part.split('_')
    if (frameSKUPrefixes.includes(skuPrefix)) return [['frame', stripMatteOrInfinitePrefix(choice)]]
    if (lensSKUPrefixes.includes(skuPrefix)) return [
      ['lens', choice.replace(/^8/, '') + frameType],
      ['engraving', 'eN'],
      ['reflections', `r5pc${frameType}`]
    ]
    if (iconSKUPrefixes.includes(skuPrefix)) return [['icons', choice]]
    if (earsockSKUPrefixes.includes(skuPrefix)) return [['earsocks', choice]]
    if (bottomSKUPrefixes.includes(skuPrefix)) return [['bottom', stripMatteOrInfinitePrefix(choice)]]
    if (noseSKUPrefixes.includes(skuPrefix)) return [['nose', stripMatteOrInfinitePrefix(choice)]]

    return null
  }).filter(choice => choice).flat())
}

export function getSKUWithoutPrefix (sku) {
  return sku?.split('_')?.[1]
}

export default {
  imgproxySettings,
  mobileImgproxySettings
}