export const mediaFragment = (
  mediaType: string = "image",
  imgixParams: string | null = null,
  autoFormat: boolean = true
): string => {
  if (autoFormat) {
    if (imgixParams) {
      imgixParams = `${imgixParams.replace(/}\s*$/, "")}, auto: [format] }`;
    } else {
      imgixParams = "{ auto: [format] }";
    }
  }

  return `${mediaType} {
    id
    alt
    height
    width
    title
    format
    src: url(imgixParams: ${imgixParams})
    focalPoint {
      x
      y
    }
    thumbhash
    video {
      muxPlaybackId
    }
  }`;
};

export type Media = {
  id: string;
  alt: string;
  height: number;
  width: number;
  title: string;
  format: string;
  src: string;
  focalPoint: {
    x: number;
    y: number;
  };
  thumbhash: string;
  video: {
    muxPlaybackId: string;
  };
};

export const responsiveMediaFragment = (
  mediaType: string = "image",
  imgixParams: string | null = null,
  autoFormat: boolean = true
): string => {
  if (autoFormat) {
    if (imgixParams) {
      imgixParams = `${imgixParams.replace(/}\s*$/, "")}, auto: [format] }`;
    } else {
      imgixParams = "{ auto: [format] }";
    }
  }

  return `${mediaType} {
    id
    responsiveImage${imgixParams ? `(imgixParams: ${imgixParams})` : ""} {
      srcSet
      webpSrcSet
      sizes
      src
      width
      height
      aspectRatio
      alt
      title
      bgColor
      base64
    }
    focalPoint {
      x
      y
    }
  }`;
};

export type ResponsiveMedia = {
  id: string;
  responsiveImage: {
    srcSet: string;
    webpSrcSet: string;
    sizes: string;
    src: string;
    width: number;
    height: number;
    aspectRatio: number;
    alt: string;
    title: string;
    bgColor: string;
    base64: string;
  };
  focalPoint: {
    x: number;
    y: number;
  };
};

export const responsiveMediaTransparentFragment = (
  mediaType: string = "image",
  imgixParams: string | null = null
): string =>
  `${mediaType} {
    id
    responsiveImage${imgixParams ? `(imgixParams: ${imgixParams})` : ""} {
      srcSet
      webpSrcSet
      sizes
      src
      width
      height
      aspectRatio
      alt
      title
    }
    focalPoint {
      x
      y
    }
  }`;

  export type ResponsiveMediaTransparent = {
    id: string;
    responsiveImage: {
      srcSet: string;
      webpSrcSet: string;
      sizes: string;
      src: string;
      width: number;
      height: number;
      aspectRatio: number;
      alt: string;
      title: string;
    };
    focalPoint: {
      x: number;
      y: number;
    };
  };