import background from './background.mjs'
import {
  imgproxySettings,
  mobileImgproxySettings,
  lifestyleLensTechRemovalPattern,
  stripMatteOrInfinitePrefix
} from './shared.mjs'

export const frame = {
  id: 'frame',
  name: 'Frame',
  imgproxySettings,
  mobileImgproxySettings,
  options: {
    Fclear: {
      pathTemplate: 'gs://sg360/220601/SG09/SG09f/SG09f_Fclear_{nnn}.png',
      staticPathTemplate: 'gs://sg360/230426/Tempests/SG09f_Fclear_{nnn}.png',
      override: { earsocks: 'Rgrey' }
    },
    grey: {
      pathTemplate: 'gs://sg360/220601/SG09/SG09f/SG09f_Idarkgrey_{nnn}.png',
      staticPathTemplate: 'gs://sg360/230426/Tempests/SG09f_Idarkgrey_{nnn}.png',
      override: { earsocks: 'Rgrey' }
    },
    black: {
      pathTemplate: 'gs://sg360/220601/SG09/SG09f/SG09f_Iblack_{nnn}.png',
      staticPathTemplate: 'gs://sg360/230426/Tempests/SG09f_Iblack_{nnn}.png',
      override: { earsocks: 'Rblack' }
    },
    navy: {
      pathTemplate: 'gs://sg360/220601/SG09/SG09f/SG09f_Inavy_{nnn}.png',
      staticPathTemplate: 'gs://sg360/230426/Tempests/SG09f_Inavy_{nnn}.png',
      override: { earsocks: 'Rnavy' }
    },
    carbon: {
      pathTemplate: 'gs://sg360/220601/SG09/SG09f/SG09f_Mcarbon_{nnn}.png',
      staticPathTemplate: 'gs://sg360/230426/Tempests/SG09f_Mcarbon_{nnn}.png',
      override: { earsocks: 'Rblack' }
    },
    stone: {
      pathTemplate: 'gs://sg360/220601/SG09/SG09f/SG09f_Mstone_{nnn}.png',
      staticPathTemplate: 'gs://sg360/230426/Tempests/SG09f_Mstone_{nnn}.png',
      override: { earsocks: 'Rstone' }
    },
    white: {
      pathTemplate: 'gs://sg360/220601/SG09/SG09f/SG09f_Mwhite_{nnn}.png',
      staticPathTemplate: 'gs://sg360/230426/Tempests/SG09f_Mwhite_{nnn}.png',
      override: { earsocks: 'Rwhite' }
    }
  }
}

export const icons = {
  id: 'icons',
  name: 'Icons',
  imgproxySettings,
  mobileImgproxySettings,
  options: {
    Bblack: {
      pathTemplate: 'gs://sg360/220601/SG09/SG09i/SG09i_Bblack_{nnn}.png',
      staticPathTemplate: 'gs://sg360/230426/Tempests/SG09i_Bblack_{nnn}.png'
    },
    Bblue: {
      pathTemplate: 'gs://sg360/220601/SG09/SG09i/SG09i_Bblue_{nnn}.png',
      staticPathTemplate: 'gs://sg360/230426/Tempests/SG09i_Bblue_{nnn}.png'
    },
    Bgold: {
      pathTemplate: 'gs://sg360/220601/SG09/SG09i/SG09i_Bgold_{nnn}.png',
      staticPathTemplate: 'gs://sg360/230426/Tempests/SG09i_Bgold_{nnn}.png'
    },
    Bgreen: {
      pathTemplate: 'gs://sg360/220601/SG09/SG09i/SG09i_Bgreen_{nnn}.png',
      staticPathTemplate: 'gs://sg360/230426/Tempests/SG09i_Bgreen_{nnn}.png'
    },
    Bpurple: {
      pathTemplate: 'gs://sg360/220601/SG09/SG09i/SG09i_Bpurple_{nnn}.png',
      staticPathTemplate: 'gs://sg360/230426/Tempests/SG09i_Bpurple_{nnn}.png'
    },
    Bred: {
      pathTemplate: 'gs://sg360/220601/SG09/SG09i/SG09i_Bred_{nnn}.png',
      staticPathTemplate: 'gs://sg360/230426/Tempests/SG09i_Bred_{nnn}.png'
    },
    Bsilver: {
      pathTemplate: 'gs://sg360/220601/SG09/SG09i/SG09i_Bsilver_{nnn}.png',
      staticPathTemplate: 'gs://sg360/230426/Tempests/SG09i_Bsilver_{nnn}.png'
    },
    Bwhite: {
      pathTemplate: 'gs://sg360/220601/SG09/SG09i/SG09i_Bwhite_{nnn}.png',
      staticPathTemplate: 'gs://sg360/230426/Tempests/SG09i_Bwhite_{nnn}.png'
    }
  }
}

export const lenses = {
  id: 'lens',
  name: 'Lenses',
  imgproxySettings,
  mobileImgproxySettings,
  options: {
    blue: {
      pathTemplate: 'gs://sg360/220601/SG09/SG09le/SG09le_blue_{nnn}.png',
      staticPathTemplate: 'gs://sg360/230426/Tempests/SG09le_blue_{nnn}.png'
    },
    fire: {
      pathTemplate: 'gs://sg360/220601/SG09/SG09le/SG09le_fire_{nnn}.png',
      staticPathTemplate: 'gs://sg360/230426/Tempests/SG09le_fire_{nnn}.png'
    },
    gold: {
      pathTemplate: 'gs://sg360/220601/SG09/SG09le/SG09le_gold_{nnn}.png',
      staticPathTemplate: 'gs://sg360/230426/Tempests/SG09le_gold_{nnn}.png'
    },
    green: {
      pathTemplate: 'gs://sg360/220601/SG09/SG09le/SG09le_green_{nnn}.png',
      staticPathTemplate: 'gs://sg360/230426/Tempests/SG09le_green_{nnn}.png'
    },
    purple: {
      pathTemplate: 'gs://sg360/220601/SG09/SG09le/SG09le_purple_{nnn}.png',
      staticPathTemplate: 'gs://sg360/230426/Tempests/SG09le_purple_{nnn}.png'
    },
    red: {
      pathTemplate: 'gs://sg360/220601/SG09/SG09le/SG09le_red_{nnn}.png',
      staticPathTemplate: 'gs://sg360/230426/Tempests/SG09le_red_{nnn}.png'
    },
    silverblue: {
      pathTemplate: 'gs://sg360/220601/SG09/SG09le/SG09le_silverblue_{nnn}.png',
      staticPathTemplate: 'gs://sg360/230426/Tempests/SG09le_silverblue_{nnn}.png'
    },
    smoke: {
      pathTemplate: 'gs://sg360/220601/SG09/SG09le/SG09le_smoke_{nnn}.png',
      staticPathTemplate: 'gs://sg360/230426/Tempests/SG09le_smoke_{nnn}.png'
    },
    clear: {
      pathTemplate: 'gs://sg360/270624/Tempests/rgle/rgle_clear_{nnn}.png',
      staticPathTemplate: 'gs://sg360/270624/Tempests/rgle/rgle_clear_{nnn}.png'
    }
  }
}

export const earsocks = {
  id: 'earsocks',
  name: 'Earsocks',
  imgproxySettings,
  mobileImgproxySettings,
  options: {
    Rblack: {
      pathTemplate: 'gs://sg360/220601/SG09/SG09es/SG09es_Rblack_{nnn}.png',
      staticPathTemplate: 'gs://sg360/230426/Tempests/SG09es_Rblack_{nnn}.png'
    },
    Rgrey: {
      pathTemplate: 'gs://sg360/220601/SG09/SG09es/SG09es_Rgrey_{nnn}.png',
      staticPathTemplate: 'gs://sg360/230426/Tempests/SG09es_Rgrey_{nnn}.png'
    },
    Rnavy: {
      pathTemplate: 'gs://sg360/220601/SG09/SG09es/SG09es_Rnavy_{nnn}.png',
      staticPathTemplate: 'gs://sg360/230426/Tempests/SG09es_Rnavy_{nnn}.png'
    },
    Rstone: {
      pathTemplate: 'gs://sg360/220601/SG09/SG09es/SG09es_Rstone_{nnn}.png',
      staticPathTemplate: 'gs://sg360/230426/Tempests/SG09es_Rstone_{nnn}.png'
    },
    Rwhite: {
      pathTemplate: 'gs://sg360/220601/SG09/SG09es/SG09es_Rwhite_{nnn}.png',
      staticPathTemplate: 'gs://sg360/230426/Tempests/SG09es_Rwhite_{nnn}.png'
    }
  }
}

export const engraving = {
  id: 'engraving',
  name: 'Engraving',
  imgproxySettings,
  mobileImgproxySettings,
  options: {
    eN: {
      pathTemplate: 'gs://sg360/220601/SG09/SG09lg/SG09lg_N_{nnn}.png'
    },
    eP: {
      pathTemplate: 'gs://sg360/220601/SG09/SG09lg/SG09lg_P_{nnn}.png'
    },
    e8: {
      pathTemplate: 'gs://sg360/220601/SG09/SG09lg/SG09lg_8_{nnn}.png'
    },
    e8P: {
      pathTemplate: 'gs://sg360/220601/SG09/SG09lg/SG09lg_8P_{nnn}.png'
    },
    none: {
      disabled: true
    }
  }
}

export function resolveOptions (customisedProduct) {
  const engraving = {
    '4ko': 'eN',
    '4kop': 'eP',
    '8ko': 'e8',
    '8kop': 'e8P'
  }[customisedProduct.lensTech] || 'none'

  const choices = {
    frame: stripMatteOrInfinitePrefix(customisedProduct.frameChoice?.skuWithoutPrefix),
    lens: customisedProduct.lensChoice.skuWithoutLensTech.split('_')?.[1],
    icons: customisedProduct.state.choices.i?.split('_')?.[1],
    engraving
  }

  // Determine earsocks from override set in frame definition
  const frameOverride = frame.options[choices.frame]?.override
  choices.earsocks = frameOverride?.earsocks || null

  return choices
}

export function resolveOptionsFromSKU (sku) {
  const frameSKUPrefixes = ['tf']
  const lensSKUPrefixes = ['tle', 'rgle']
  const iconSKUPrefixes = ['ti', 'i']

  const getEngraving = (choice) => {
    const engravings = [
      [/^N/g, 'eN'],
      [/^P/g, 'eP'],
      [/^8(?!P|IR)/g, 'e8'],
      [/^8P/g, 'e8P']
    ]
    return engravings.find(([pattern, _option]) => choice.match(pattern))?.[1] || 'none'
  }

  const parts = sku.split('-').slice(1)
  const choices = Object.fromEntries(parts.map((part) => {
    const [skuPrefix, choice] = part.split('_')
    if (frameSKUPrefixes.includes(skuPrefix)) return [['frame', stripMatteOrInfinitePrefix(choice)]]
    if (lensSKUPrefixes.includes(skuPrefix)) return [
      ['lens', choice.replace(lifestyleLensTechRemovalPattern, '')],
      ['engraving', getEngraving(choice)],
      ['reflections', 'r5pc']
    ]
    if (iconSKUPrefixes.includes(skuPrefix)) return [['icons', choice]]

    return null
  }).filter(choice => choice).flat())
  
  // Determine earsocks from override set in frame definition
  const frameOverride = frame.options[choices.frame]?.override
  choices.earsocks = frameOverride?.earsocks || null

  return choices
}

export default {
  layers: [
    background,
    frame,
    earsocks,
    icons,
    lenses,
    engraving
  ],
  resolveOptions,
  resolveOptionsFromSKU
}