import { locales } from '~/i18n/constants'
import cookie from 'js-cookie'
import { ref, computed } from '#imports'

export default defineNuxtPlugin(async () => {
  const route = useRoute()

  const locale = ref('en-gb')

  const localeProperties = computed(() => {
    return locales.find(
      (configLocale) => configLocale.code === locale.value
    )
  })

  function setLocale(localeCode) {
    cookie.set('i18n_redirected', localeCode, { expires: 28 })
    locale.value = localeCode
  }

  /**
   *
   * We set the locale before all else so that the correct market is set for commerce layer
   * amongst other things.
   *
   * It's vital this is done before anything else happens on the site to avoid
   * showing the wrong prices for the wrong locale.
   *
   * We handle this ourselves due to the no_prefix setting on the i18n package.
   *
   * Here we pluck the locale from URL init $i18n with that locale so that all other
   * functionality later that hooks into $i18n is localised correctly.
   *
   */

  const matchedLocale = locales.find((locale) => {
    return (
      route.params.locale === locale.code ||
      route.fullPath.startsWith('/' + locale.code)
    )
  })

  if(matchedLocale) {
    await setLocale(matchedLocale.code)
  } else {
    await setLocale(locale.value)
  }

  function getLocaleHead () {
    const unwantedQueryParamPatterns = [
      /^utm_/i,
      /^ref/i,
      /^fbclid$/i,
      /^gclid$/i,
      /^ab-/i,
    ]

    const queryParams = new URLSearchParams(route.query)

    // Filter out unwanted query parameters
    unwantedQueryParamPatterns.forEach((pattern) => {
      for (const param of queryParams.keys()) {
        if (pattern.test(param)) {
          queryParams.delete(param)
        }
      }
    })

    // Construct the clean path
    const cleanPath = route.path + (queryParams.toString() ? `?${queryParams}` : '')

    const metaObject = {
      htmlAttrs: {
        lang: localeProperties.value.iso
      },
      link: [],
      meta: [{
        property: 'og:locale',
        content: localeProperties.value.iso,
        hid: 'i18n-og-locale'
      }, {
        property: 'og:url',
        content: `https://www.sungod.co${cleanPath}`,
        hid: 'og:url'
      }]
    }

    const routeWithLocale = l => route.path.replace(/^\/([a-z]{2}-[a-z]{2})/, '/' + l.code)

    locales.forEach((l) => {
      const isCurrent = l.code === locale.value

      if (l.code === 'en-gb') {
        metaObject.link.push({
          rel: 'alternate',
          hreflang: 'x-default',
          href: `https://www.sungod.co${routeWithLocale(l)}`,
          hid: `i18n-alt-x-default`
        })
      }

      if (isCurrent) {
        metaObject.link.push({
          rel: 'canonical',
          href: `https://www.sungod.co${routeWithLocale(l)}`,
          hid: `i18n-canonical`
        })

        metaObject.link.push({
          rel: 'alternate',
          hreflang: l.iso,
          href: `https://www.sungod.co${routeWithLocale(l)}`,
          hid: `i18n-alt-${l.code}`
        })
      } else {
        metaObject.link.push({
          rel: 'alternate',
          hreflang: l.iso,
          href: `https://www.sungod.co${routeWithLocale(l)}`,
          hid: `i18n-alt-${l.code}`
        })

        metaObject.meta.push({
          property: 'og:locale:alternate',
          content: l.iso,
          hid: `i18n-og-${l.code}`
        })
      }
    })

    return metaObject
  }

  /**
   *
   *
   * We provide these as a way to stub the @nuxtjs/i18n module and make some
   * of its methods and properties available in the same way the official
   * module would.
   *
   * At the time of writing the module had not stabalised for Nuxt 3.
   *
   */

  return {
    provide: {
      i18n: {
        locale,
        locales,
        localeProperties,
        getLocaleHead
      },
    },
  }
})
