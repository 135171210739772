export const autoAppliedDiscountCodesQuery = `
query {
  allAutoAppliedProductDiscountCodes {
    discountCode {
      discountCodeId
    }
    requiredProductsInCart {
      baseSku
    }
  }
}
`