import {
  imgproxySettings,
  mobileImgproxySettings,
  resolveLifestyleOptionsFromSKU as resolveOptionsFromSKU,
  stripMatteOrInfinitePrefix
} from './shared.mjs'

export const frame = {
  id: 'frame',
  name: 'Frame',
  imgproxySettings,
  mobileImgproxySettings,
  options: {
    tortoise: {
      pathTemplate: 'gs://sg360/220620/Zephyrs/zf/zf_Itortoise_{nnn}.png',
      staticPathTemplate: 'gs://sg360/230412/Zephyrs/zf_tortoise_{nnn}.png',
      testPathTemplate: 'gs://sg360/250212/v2/zf_tortoise_{nnn}.png'
    },
    blacktortoise: {
      pathTemplate: 'gs://sg360/220620/Zephyrs/zf/zf_Iblacktortoise_{nnn}.png',
      staticPathTemplate: 'gs://sg360/230412/Zephyrs/zf_blacktortoise_{nnn}.png',
      testPathTemplate: 'gs://sg360/250212/v2/zf_blacktortoise_{nnn}.png'
    },
    grey: {
      pathTemplate: 'gs://sg360/220620/Zephyrs/zf/zf_Idarkgrey_{nnn}.png',
      staticPathTemplate: 'gs://sg360/230412/Zephyrs/zf_darkgrey_{nnn}.png',
      testPathTemplate: 'gs://sg360/250212/v2/zf_grey_{nnn}.png'
    },
    stone: {
      pathTemplate: 'gs://sg360/220620/Zephyrs/zf/zf_Istone_{nnn}.png',
      staticPathTemplate: 'gs://sg360/230412/Zephyrs/zf_stone_{nnn}.png',
      testPathTemplate: 'gs://sg360/250212/v2/zf_stone_{nnn}.png'
    },
    navy: {
      pathTemplate: 'gs://sg360/220620/Zephyrs/zf/zf_Inavy_{nnn}.png',
      staticPathTemplate: 'gs://sg360/230412/Zephyrs/zf_navy_{nnn}.png',
      testPathTemplate: 'gs://sg360/250212/v2/zf_navy_{nnn}.png'
    },
    black: {
      pathTemplate: 'gs://sg360/220620/Zephyrs/zf/zf_Iblack_{nnn}.png',
      staticPathTemplate: 'gs://sg360/230412/Zephyrs/zf_black_{nnn}.png',
      testPathTemplate: 'gs://sg360/250212/v2/zf_black_{nnn}.png'
    },
    blue: {
      pathTemplate: 'gs://sg360/250314/Z_Fblue-{nnn}.png',
      staticPathTemplate: 'gs://sg360/250314/Z_Fblue-{nnn}.png',
      testPathTemplate: 'gs://sg360/250314/Z_Fblue-{nnn}.png'
    }
  }
}

export const logos = {
  id: 'logos',
  name: 'Logos',
  imgproxySettings,
  mobileImgproxySettings,
  options: {
    Bblack: {
      pathTemplate: 'gs://sg360/220620/Zephyrs/zi/zi_Bblack_{nnn}.png',
      staticPathTemplate: 'gs://sg360/230412/Zephyrs/zi_black_{nnn}.png',
      testPathTemplate: 'gs://sg360/250212/v2/zi_black_{nnn}.png'
    },
    Bblue: {
      pathTemplate: 'gs://sg360/220620/Zephyrs/zi/zi_Bblue_{nnn}.png',
      staticPathTemplate: 'gs://sg360/230412/Zephyrs/zi_blue_{nnn}.png',
      testPathTemplate: 'gs://sg360/250212/v2/zi_blue_{nnn}.png'
    },
    Bgold: {
      pathTemplate: 'gs://sg360/220620/Zephyrs/zi/zi_Bgold_{nnn}.png',
      staticPathTemplate: 'gs://sg360/230412/Zephyrs/zi_gold_{nnn}.png',
      testPathTemplate: 'gs://sg360/250212/v2/zi_gold_{nnn}.png'
    },
    Bgunmetal: {
      pathTemplate: 'gs://sg360/220620/Zephyrs/zi/zi_Bgunmetal_{nnn}.png',
      staticPathTemplate: 'gs://sg360/230412/Zephyrs/zi_gunmetal_{nnn}.png',
      testPathTemplate: 'gs://sg360/250212/v2/zi_gunmetal_{nnn}.png'
    },
    Brosegold: {
      pathTemplate: 'gs://sg360/220620/Zephyrs/zi/zi_Brosegold_{nnn}.png',
      staticPathTemplate: 'gs://sg360/230412/Zephyrs/zi_rosegold_{nnn}.png',
      testPathTemplate: 'gs://sg360/250212/v2/zi_rosegold_{nnn}.png'
    },
    Bsilver: {
      pathTemplate: 'gs://sg360/220620/Zephyrs/zi/zi_Bsilver_{nnn}.png',
      staticPathTemplate: 'gs://sg360/230412/Zephyrs/zi_silver_{nnn}.png',
      testPathTemplate: 'gs://sg360/250212/v2/zi_silver_{nnn}.png'
    }
  }
}

export const lenses = {
  id: 'lens',
  name: 'Lenses',
  imgproxySettings,
  mobileImgproxySettings,
  options: {
    chrome: {
      pathTemplate: 'gs://sg360/220620/Zephyrs/zle/zle_chrome_{nnn}.png',
      staticPathTemplate: 'gs://sg360/230412/Zephyrs/zle_chrome_{nnn}.png',
      testPathTemplate: 'gs://sg360/250212/v2/zle_chrome_{nnn}.png'
    },
    brown: {
      pathTemplate: 'gs://sg360/220620/Zephyrs/zle/zle_brown_{nnn}.png',
      staticPathTemplate: 'gs://sg360/230412/Zephyrs/zle_brown_{nnn}.png',
      testPathTemplate: 'gs://sg360/250212/v2/zle_brown_{nnn}.png'
    },
    gold: {
      pathTemplate: 'gs://sg360/220620/Zephyrs/zle/zle_gold_{nnn}.png',
      staticPathTemplate: 'gs://sg360/230412/Zephyrs/zle_gold_{nnn}.png',
      testPathTemplate: 'gs://sg360/250212/v2/zle_gold_{nnn}.png'
    },
    rose: {
      pathTemplate: 'gs://sg360/220620/Zephyrs/zle/zle_rose_{nnn}.png',
      staticPathTemplate: 'gs://sg360/230412/Zephyrs/zle_rose_{nnn}.png',
      testPathTemplate: 'gs://sg360/250212/v2/zle_rose_{nnn}.png'
    },
    silverblue: {
      pathTemplate: 'gs://sg360/220620/Zephyrs/zle/zle_silverblue_{nnn}.png',
      staticPathTemplate: 'gs://sg360/230412/Zephyrs/zle_silverblue_{nnn}.png',
      testPathTemplate: 'gs://sg360/250212/v2/zle_silverblue_{nnn}.png'
    },
    smoke: {
      pathTemplate: 'gs://sg360/220620/Zephyrs/zle/zle_smoke_{nnn}.png',
      staticPathTemplate: 'gs://sg360/230412/Zephyrs/zle_smoke_{nnn}.png',
      testPathTemplate: 'gs://sg360/250212/v2/zle_smoke_{nnn}.png'
    },
    clear: {
      pathTemplate: 'gs://sg360/270624/Zephyrs/zle/zle_clear_{nnn}.png',
      staticPathTemplate: 'gs://sg360/270624/Zephyrs/zle/zle_clear_{nnn}.png',
      testPathTemplate: 'gs://sg360/270624/Zephyrs/zle/zle_clear_{nnn}.png'
    },
    green: {
      pathTemplate: 'gs://sg360/250314/zle_green_{nnn}.png',
      staticPathTemplate: 'gs://sg360/250314/zle_green_{nnn}.png',
      testPathTemplate: 'gs://sg360/250314/zle_green_{nnn}.png'
    }
  }
}

export const engraving = {
  id: 'engraving',
  name: 'Engraving',
  imgproxySettings,
  mobileImgproxySettings,
  options: {
    eN: {
      pathTemplate: 'gs://sg360/220620/Zephyrs/zlg/zlg_N_{nnn}.png'
    },
    eP: {
      pathTemplate: 'gs://sg360/220620/Zephyrs/zlg/zlg_P_{nnn}.png'
    },
    e8: {
      pathTemplate: 'gs://sg360/220620/Zephyrs/zlg/zlg_8_{nnn}.png',
      testPathTemplate: 'gs://sg360/250212/zlg_8_{nnn}.png'
    },
    e8P: {
      pathTemplate: 'gs://sg360/220620/Zephyrs/zlg/zlg_8P_{nnn}.png',
      testPathTemplate: 'gs://sg360/250212/v2/zleg_engrvaing_{nnn}.png'
    },
    none: {
      disabled: true
    }
  }
}

export const background = {
  id: 'background',
  name: 'Background',
  imgproxySettings: [
    'insecure',
    'resize:fill:1400',
    'plain'
  ],
  mobileImgproxySettings: [
    'insecure',
    'resize:fill:800',
    'plain'
  ],
  isBackground: true,
  options: {
    default: {
      pathTemplate: null,
      testPathTemplate: 'gs://sg360/250212/v2/z_Background-shadow_{nnn}-66.png'
    }
  }
}

export function resolveOptions (customisedProduct) {
  const engraving = {
    '4ko': 'eN',
    '4kop': 'eP',
    '8ko': 'e8',
    '8kop': 'e8P'
  }[customisedProduct.lensTech] || 'none'

  return {
    frame: stripMatteOrInfinitePrefix(customisedProduct.frameChoice?.skuWithoutPrefix),
    lens: customisedProduct.lensChoice.skuWithoutLensTech.split('_')?.[1],
    logos: customisedProduct.state.choices.ll?.split('_')?.[1],
    engraving
  }
}

export default {
  layers: [
    background,
    frame,
    logos,
    lenses,
    engraving
  ],
  resolveOptions,
  resolveOptionsFromSKU
}
