import { PAGE_THEMES, DEFAULT_PAGE_THEME } from '~/assets/js/constants/pageThemes'

export const isValidPageTheme = (themeToValidate) => {
  return Object.keys(PAGE_THEMES).includes(themeToValidate)
}

export const setPageTheme = (store, theme) => {
  const pageTheme = theme || DEFAULT_PAGE_THEME
  const validatedPageTheme =
    isValidPageTheme(pageTheme) ? pageTheme : DEFAULT_PAGE_THEME
  return store.setPageTheme(validatedPageTheme)
}

export const setPageThemeFromRoute = (store, route) => {
  const pageTheme = route.meta.theme || DEFAULT_PAGE_THEME
  const validatedPageTheme =
    isValidPageTheme(pageTheme) ? pageTheme : DEFAULT_PAGE_THEME

  return store.setPageTheme(validatedPageTheme)
}

export const getTheme = (rawTheme) => {
  return PAGE_THEMES[rawTheme]
}
