import background from './background.mjs'
import {
  imgproxySettings,
  mobileImgproxySettings,
  resolveLifestyleOptionsFromSKU as resolveOptionsFromSKU,
  stripMatteOrInfinitePrefix
} from './shared.mjs'

export const frame = {
  id: 'frame',
  name: 'Frame',
  imgproxySettings,
  mobileImgproxySettings,
  options: {
    black: {
      pathTemplate: 'gs://sg360/240716/Miras/mf_Iblack_{nnn}.png',
      staticPathTemplate: 'gs://sg360/240716/Miras/mf_Iblack_{nnn}.png'
    },
    fblue: {
      pathTemplate: 'gs://sg360/240723/mf_Iblue_{nnn}.png',
      staticPathTemplate: 'gs://sg360/240723/mf_Iblue_{nnn}.png'
    },
    fchampagne: {
      pathTemplate: 'gs://sg360/240717/Miras/mf_Ichampagne_{nnn}.png',
      staticPathTemplate: 'gs://sg360/240717/Miras/mf_Ichampagne_{nnn}.png'
    },
    mist: {
      pathTemplate: 'gs://sg360/240730/mf_Ichampagne_{nnn}.png',
      staticPathTemplate: 'gs://sg360/240730/mf_Ichampagne_{nnn}.png'
    },
    grape: {
      pathTemplate: 'gs://sg360/240717/Miras/mf_Igrape_{nnn}.png',
      staticPathTemplate: 'gs://sg360/240717/Miras/mf_Igrape_{nnn}.png'
    },
    tortoise: {
      pathTemplate: 'gs://sg360/240717/Miras/mf_Itortoise_{nnn}.png',
      staticPathTemplate: 'gs://sg360/240717/Miras/mf_Itortoise_{nnn}.png'
    }
  }
}

export const logos = {
  id: 'logos',
  name: 'Logos',
  imgproxySettings,
  mobileImgproxySettings,
  options: {
    Bblack: {
      pathTemplate: 'gs://sg360/240716/Miras/mi_black_{nnn}.png',
      staticPathTemplate: 'gs://sg360/240716/Miras/mi_black_{nnn}.png'
    },
    Bblue: {
      pathTemplate: 'gs://sg360/240716/Miras/mi_blue_{nnn}.png',
      staticPathTemplate: 'gs://sg360/240716/Miras/mi_blue_{nnn}.png'
    },
    Bgold: {
      pathTemplate: 'gs://sg360/240716/Miras/mi_gold_{nnn}.png',
      staticPathTemplate: 'gs://sg360/240716/Miras/mi_gold_{nnn}.png'
    },
    Bgunmetal: {
      pathTemplate: 'gs://sg360/240716/Miras/mi_gunmetal_{nnn}.png',
      staticPathTemplate: 'gs://sg360/240716/Miras/mi_gunmetal_{nnn}.png'
    },
    Brosegold: {
      pathTemplate: 'gs://sg360/240716/Miras/mi_rosegold_{nnn}.png',
      staticPathTemplate: 'gs://sg360/240716/Miras/mi_rosegold_{nnn}.png'
    },
    Bsilver: {
      pathTemplate: 'gs://sg360/240716/Miras/mi_silver_{nnn}.png',
      staticPathTemplate: 'gs://sg360/240716/Miras/mi_silver_{nnn}.png'
    },
    Bgrape: {
      pathTemplate: 'gs://sg360/240717/Miras/mi_grape_{nnn}.png',
      staticPathTemplate: 'gs://sg360/240717/Miras/mi_grape_{nnn}.png'
    }
  }
}

export const lenses = {
  id: 'lens',
  name: 'Lenses',
  imgproxySettings,
  mobileImgproxySettings,
  options: {
    brown: {
      pathTemplate: 'gs://sg360/240717/Miras/mle_brown_{nnn}.png',
      staticPathTemplate: 'gs://sg360/240717/Miras/mle_brown_{nnn}.png'
    },
    gold: {
      pathTemplate: 'gs://sg360/240717/Miras/mle_gold_{nnn}.png',
      staticPathTemplate: 'gs://sg360/240717/Miras/mle_gold_{nnn}.png'
    },
    grape: {
      pathTemplate: 'gs://sg360/240717/Miras/mle_grape_{nnn}.png',
      staticPathTemplate: 'gs://sg360/240717/Miras/mle_grape_{nnn}.png'
    },
    grn: {
      pathTemplate: 'gs://sg360/240717/Miras/mle_green_{nnn}.png',
      staticPathTemplate: 'gs://sg360/240717/Miras/mle_green_{nnn}.png'
    },
    rose: {
      pathTemplate: 'gs://sg360/240717/Miras/mle_rose_{nnn}.png',
      staticPathTemplate: 'gs://sg360/240717/Miras/mle_rose_{nnn}.png'
    },
    silverblue: {
      pathTemplate: 'gs://sg360/240717/Miras/mle_silverblue_{nnn}.png',
      staticPathTemplate: 'gs://sg360/240717/Miras/mle_silverblue_{nnn}.png'
    },
    smoke: {
      pathTemplate: 'gs://sg360/240717/Miras/mle_smoke_{nnn}.png',
      staticPathTemplate: 'gs://sg360/240717/Miras/mle_smoke_{nnn}.png'
    },
    clear: {
      pathTemplate: 'gs://sg360/250128/Miras_PrescriptionLens_{nnn}.png',
      staticPathTemplate: 'gs://sg360/250128/Miras_PrescriptionLens_{nnn}.png'
    }
  }
}

export const engraving = {
  id: 'engraving',
  name: 'Engraving',
  imgproxySettings,
  mobileImgproxySettings,
  options: {
    eN: {
      pathTemplate: 'gs://sg360/240716/Miras/mlg_N_{nnn}.png',
    },
    eP: {
      pathTemplate: 'gs://sg360/240716/Miras/mlg_P_{nnn}.png',
    },
    e8: {
      pathTemplate: 'gs://sg360/240716/Miras/mlg_8_{nnn}.png',
    },
    e8P: {
      pathTemplate: 'gs://sg360/240716/Miras/mlg_8P_{nnn}.png',
    },
    none: {
      disabled: true
    }
  }
}

export function resolveOptions (customisedProduct) {
  const engraving = {
    '4ko': 'eN',
    '4kop': 'eP',
    '8ko': 'e8',
    '8kop': 'e8P'
  }[customisedProduct.lensTech] || 'none'

  return {
    frame: stripMatteOrInfinitePrefix(customisedProduct.frameChoice?.skuWithoutPrefix),
    lens: customisedProduct.lensChoice.skuWithoutLensTech.split('_')?.[1],
    logos: customisedProduct.state.choices.ll?.split('_')?.[1],
    engraving
  }
}

export default {
  supports360: false,
  layers: [
    background,
    frame,
    logos,
    lenses,
    engraving
  ],
  resolveOptions,
  resolveOptionsFromSKU
}
