import { reactive } from 'vue'
import { ProductOption } from './'
import { getSkuWithoutLensTech } from '~/assets/js/helpers/product'
import dayjs from 'dayjs'

const FIELDS = [
  'id',
  'commerceId',
  'sku',
  'verboseName',
  'partType',
  'vlt',
  'material',
  'quantity',
  'productNameOverride',
  'doNotTrackStock',
  'allowPreorders',
  'isLimitedEdition',
  'cartDetailsOverride',
  'lifestyleImagesOverride',
  'inboundQuantity',
  'inboundDeliveryDate',
  'preorderAsDelayedDespatch',
  'additionalProductSKU',
  'productOptions',
  'tags',
  'optionTech',
  'despatchDelayedUntil',
  'buttonToolTipTitle',
  'buttonToolTipContent'
]

export default class PartOption {
  constructor(data) {
    this._rawData = data

    FIELDS.forEach((field) => {
      this[field] = data[field]
    })
    this._name = data.name
    this.setPricesFromRawData()
    this.addProductOptions()

    this.leaderSKUOverride = data.leaderSkuOverride?.sku

    // Trick Vue into thinking this instance already has an observer to prevent it adding a new one
    this.__ob__ = reactive({}).__ob__
  }

  setPricesFromRawData() {
    this.currencyCode = this._rawData.currency_code
    this.amountFloat = this._rawData.amount_float
    this.amountFloatGBP = this._rawData.amount_float_gbp
    this.compareAtAmountFloat = this._rawData.compare_at_amount_float
  }

  addProductOptions() {
    if (!this._rawData.productOptions?.length)
      return this._rawData.productOptions

    this.productOptions = this._rawData.productOptions.map((productOption) => {
      return new ProductOption({
        ...this._rawData,
        ...productOption,
        amountFloat: productOption.amount_float + this._rawData.amount_float,
        amountFloatGBP: productOption.amount_float_gbp + this._rawData.amount_float_gbp,
        compareAtAmountFloat: this.compareAtAmountFloat,
      })
    })
  }

  addSwatch(getSwatch) {
    const swatch = this._rawData.swatchStyle
    if (!swatch) return null

    this.swatch = getSwatch(swatch.id)

    return this.swatch
  }

  setActiveProductOption(id) {
    if (!this.productOptions?.length) return null

    this._activeProductOption =
      this.productOptions.find((productOption) => productOption.id === id) ||
      null
  }

  getSKUWithPrefix(prefix) {
    return `${prefix}_${this.sku.split('_')[1]}`
  }

  get activeProductOption() {
    return this._activeProductOption
  }

  get hasProductOptions() {
    return !!this.productOptions.length
  }

  get price() {
    if (this.activeProductOption) return this.activeProductOption.price

    return this.amountFloat
  }

  get priceGBP() {
    if (this.activeProductOption) return this.activeProductOption.priceGBP

    return this.amountFloatGBP
  }

  get compareAtPrice() {
    return this.compareAtAmountFloat
  }

  get lensTech() {
    if (this.partType !== 'lenses') throw new Error('Option is not a lens')

    if (this.activeProductOption?.lensTech)
      return this.activeProductOption.lensTech

    const lookup = [
      [/le_N/g, '4ko'],
      [/le_P/g, '4kop'],
      [/le_8(?!P|IR)/g, '8ko'],
      [/le_8P/g, '8kop'],
      [/le_8IR/g, 'iris'],
      [/le_[a-z]{3,10}N/g, '4kosnow'],
      [/le_[a-z]{3,10}8N/g, '8kosnow'],
      [/le_RXclear/g, 'rxclear'],
      [/le_RX/g, 'rx'],
      [/le_STRX/g, 'st'],
      [/le_STPRX/g, 'stp'],
      [/le_THRX/g, 'th'],
      [/le_THPRX/g, 'thp'],
      [/le_STCRXclear/g, 'stc'],
      [/le_STBRXclear/g, 'stb'],
      [/le_THCRXclear/g, 'thc'],
      [/le_THBRXclear/g, 'thb']
    ]

    return (lookup.find(([pattern, _tech]) => this.sku.match(pattern)) ||
      lookup[0])[1]
  }

  get skuWithoutLensTech() {
    return getSkuWithoutLensTech(this.sku, this.partType)
  }

  get skuWithoutPrefix() {
    return this.sku.split('_')?.[1]
  }

  getSKUWithLensTech(lensTech) {
    // Only lenses have a lens tech to be changed
    if (this.partType !== 'lenses') return this.sku

    
    // Use a regex replace based on a static pattern
    // to identify the current colour (group 2 = $2) and a dynamic pattern
    // to define the SKU format for the requested lens tech
    const searchPattern =
      /le_(N|P|8P|8|STRX|STPRX|THRX|THPRX|STCRX|THCRX|STBRX|THBRX)?([a-z]*)(N|8N)?/
    const replacePatterns = {
      '4ko': 'le_N$2',
      '4kop': 'le_P$2',
      '8ko': 'le_8$2',
      '8kop': 'le_8P$2',
      'st': 'le_STRX$2',
      'stp': 'le_STPRX$2',
      'th': 'le_THRX$2',
      'thp': 'le_THPRX$2',
      'stc': 'le_STCRX$2',
      'thc': 'le_THCRX$2',
      'stb': 'le_STBRX$2',
      'thb': 'le_THBRX$2',
      '4kosnow': 'le_$2N',
      '8kosnow': 'le_$28N',
    }
    const replacePattern = replacePatterns[lensTech]
    if (!replacePattern) return this.sku // Unexpected lens tech, just return the current SKU
    return this.sku.replace(searchPattern, replacePattern)
  }

  get isInStock() {
    return this.quantity > 0 || this.doNotTrackStock
  }

  get isOnPreorder() {
    return (
      !this.isInStock && this.inboundDeliveryDate && this.allowPreorders
    )
  }

  get isDelayedDespatch () {
    if (this.despatchDelayedUntil) {
      // Return true if the delayed despatch date is in the future
      return dayjs().isBefore(dayjs(this.despatchDelayedUntil))
    }

    if (this.preorderAsDelayedDespatch) {
      return this.isOnPreorder
    }

    return false
  }

  get delayedDespatchDate () {
    return [
      this.despatchDelayedUntil,
      this.preorderAsDelayedDespatch && this.inboundDeliveryDate
    ].filter(Boolean).map(date => dayjs(date)).sort((a, b) => a.diff(b))[0]
  }

  get isIrisLens() {
    return this.sku.includes('le_8IR')
  }

  get isRecycled() {
    return this.sku.includes('_I')
  }

  get isWaterLens() {
    return this.sku.includes('water')
  }
  
  get isCat4Lens() {
    return this.sku.includes('le_8chrome4')
  }

  get isNonRecycled() {
    return this.sku.includes('_F') || this.sku.includes('_M')
  }

  get name() {
    return this._name
      ?.replace(/([4|8])KO /, '$1KO® ')
      .replace(/([4|8])KOsnow/, '$1KO®snow')
      .replace('®', '<sup>®</sup>')
  }

  get plaintextName() {
    return this._name
  }

  get nameWithoutLensTech() {
    return this.name.replace(/^[48]KO.*? /, '').replace(/Polarised /, '')
  }

  toJSON() {
    return this._rawData
  }

  static fromJSON(data, getSwatch) {
    const option = new PartOption(data)
    option.addSwatch(getSwatch)
    return option
  }
}
