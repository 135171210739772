export const DEFAULT_PAGE_THEME = 'default'

export const PAGE_THEMES = {
  sungod: 'default',
  'sungod-standalone': 'default-standalone',
  mclaren: 'orange-dark',
  ln4: 'ln4',
  dr3: 'dr3',
  'dr3-22': 'dr3-22',
  miami: 'miami',
  explore: 'explore',
  monaco: 'monaco',
  'mclaren-mx': 'mclaren-mx',
  silverblue: 'silverblue',
  'yellow-jersey': 'yellow-jersey',
  'dark-chrome': 'dark-chrome',
  'grape': 'grape',
  gold: 'gold',
  purple: 'purple'
}
