import background from './background.mjs'
import {
  imgproxySettings,
  mobileImgproxySettings,
  getSKUWithoutPrefix,
  resolvePaceOptionsFromSKU as resolveOptionsFromSKU,
  stripMatteOrInfinitePrefix
} from './shared.mjs'

import { nose, bottom } from './vulcans.mjs'

const frameType = 'TF'

export const ghost = {
  id: 'ghost',
  name: 'Ghost Frame',
  hasOptions: false,
  imgproxySettings,
  mobileImgproxySettings,
  opacity: 0.5,
  pathTemplate: `gs://sg360/220520/Ghost Frames/Vulcans/${frameType}/Vulcans ${frameType} Ghost_{nnn}.png`
}

function resolveOptions (customisedProduct) {
  const design = customisedProduct.state.choices

  return {
    bottom: stripMatteOrInfinitePrefix(getSKUWithoutPrefix(design.cb) || 'none'),
    nose: stripMatteOrInfinitePrefix(getSKUWithoutPrefix(design.cn) || 'none')
  }
}

export default {
  layers: [
    background,
    ghost,
    nose,
    bottom
  ],
  resolveOptions,
  resolveOptionsFromSKU
}