import background from './background.mjs'
import {
  imgproxySettings,
  mobileImgproxySettings,
  resolveLifestyleSelectedOptions as resolveOptions,
  resolveLifestyleOptionsFromSKU as resolveOptionsFromSKU
} from './shared.mjs'

export const frame = {
  id: 'frame',
  name: 'Frame',
  imgproxySettings,
  mobileImgproxySettings,
  options: {
    Fclear: {
      pathTemplate: 'gs://sg360/220520/Sierras/sf/sf_Fclear_{nnn}.png',
      staticPathTemplate: 'gs://sg360/230426/Sierras/sf_Fclear_{nnn}.png'
    },
    clear: {
      pathTemplate: 'gs://sg360/220520/Sierras/sf/sf_Fclear_{nnn}.png',
      staticPathTemplate: 'gs://sg360/230426/Sierras/sf_Fclear_{nnn}.png'
    },
    grey: {
      pathTemplate: 'gs://sg360/220520/Sierras/sf/sf_Igrey_{nnn}.png',
      staticPathTemplate: 'gs://sg360/230426/Sierras/sf_MIgrey_{nnn}.png'
    },
    black: {
      pathTemplate: 'gs://sg360/220520/Sierras/sf/sf_Iblack_{nnn}.png',
      staticPathTemplate: 'gs://sg360/230426/Sierras/sf_MIblack_{nnn}.png'
    },
    navy: {
      pathTemplate: 'gs://sg360/220520/Sierras/sf/sf_Inavy_{nnn}.png',
      staticPathTemplate: 'gs://sg360/230426/Sierras/sf_MInavy_{nnn}.png'
    },
    navytortoise: {
      pathTemplate: 'gs://sg360/220520/Sierras/sf/sf_Mnavytortoise_{nnn}.png',
      staticPathTemplate: 'gs://sg360/230426/Sierras/sf_Mnavytortoise_{nnn}.png'
    },
    blacktortoise: {
      pathTemplate: 'gs://sg360/220520/Sierras/sf/sf_Mblacktortoise_{nnn}.png',
      staticPathTemplate: 'gs://sg360/230426/Sierras/sf_Mblacktortoise_{nnn}.png'
    },
    stone: {
      pathTemplate: 'gs://sg360/220520/Sierras/sf/sf_Mstone_{nnn}.png',
      staticPathTemplate: 'gs://sg360/230426/Sierras/sf_Mstone_{nnn}.png'
    },
    tortoise: {
      pathTemplate: 'gs://sg360/220520/Sierras/sf/sf_Mtortoise_{nnn}.png',
      staticPathTemplate: 'gs://sg360/230426/Sierras/sf_Mtortoise_{nnn}.png'
    },
    white: {
      pathTemplate: 'gs://sg360/220520/Sierras/sf/sf_Mwhite_{nnn}.png',
      staticPathTemplate: 'gs://sg360/230426/Sierras/sf_Mwhite_{nnn}.png'
    }
  }
}

export const icons = {
  id: 'icons',
  name: 'Icons',
  imgproxySettings,
  mobileImgproxySettings,
  options: {
    Bblack: {
      pathTemplate: 'gs://sg360/220520/Sierras/si/si_Bblack_{nnn}.png',
      staticPathTemplate: 'gs://sg360/230426/Sierras/si_Bblack_{nnn}.png'
    },
    Bblue: {
      pathTemplate: 'gs://sg360/220520/Sierras/si/si_Bblue_{nnn}.png',
      staticPathTemplate: 'gs://sg360/230426/Sierras/si_Bblue_{nnn}.png'
    },
    Bgold: {
      pathTemplate: 'gs://sg360/220520/Sierras/si/si_Bgold_{nnn}.png',
      staticPathTemplate: 'gs://sg360/230426/Sierras/si_Bgold_{nnn}.png'
    },
    Bgreen: {
      pathTemplate: 'gs://sg360/220520/Sierras/si/si_Bgreen_{nnn}.png',
      staticPathTemplate: 'gs://sg360/230426/Sierras/si_Bgreen_{nnn}.png'
    },
    Bred: {
      pathTemplate: 'gs://sg360/220520/Sierras/si/si_Bred_{nnn}.png',
      staticPathTemplate: 'gs://sg360/230426/Sierras/si_Bred_{nnn}.png'
    },
    Bsilver: {
      pathTemplate: 'gs://sg360/220520/Sierras/si/si_Bsilver_{nnn}.png',
      staticPathTemplate: 'gs://sg360/230426/Sierras/si_Bsilver_{nnn}.png'
    },
    Bwhite: {
      pathTemplate: 'gs://sg360/220520/Sierras/si/si_Bwhite_{nnn}.png',
      staticPathTemplate: 'gs://sg360/230426/Sierras/si_Bwhite_{nnn}.png'
    },
    Brosegold: {
      pathTemplate: 'gs://sg360/220520/Sierras/si/si_Brosegold_{nnn}.png',
      staticPathTemplate: 'gs://sg360/230426/Sierras/si_Brosegold_{nnn}.png'
    }
  }
}

export const lenses = {
  id: 'lens',
  name: 'Lenses',
  imgproxySettings,
  mobileImgproxySettings,
  options: {
    blue: {
      pathTemplate: 'gs://sg360/220520/Sierras/sle/sle_blue_{nnn}.png',
      staticPathTemplate: 'gs://sg360/230426/Sierras/sle_blue_{nnn}.png'
    },
    brown: {
      pathTemplate: 'gs://sg360/220520/Sierras/sle/sle_brown_{nnn}.png',
      staticPathTemplate: 'gs://sg360/230426/Sierras/sle_brown_{nnn}.png'
    },
    fire: {
      pathTemplate: 'gs://sg360/220520/Sierras/sle/sle_fire_{nnn}.png',
      staticPathTemplate: 'gs://sg360/230426/Sierras/sle_fire_{nnn}.png'
    },
    gold: {
      pathTemplate: 'gs://sg360/220520/Sierras/sle/sle_gold_{nnn}.png',
      staticPathTemplate: 'gs://sg360/230426/Sierras/sle_gold_{nnn}.png'
    },
    green: {
      pathTemplate: 'gs://sg360/220520/Sierras/sle/sle_green_{nnn}.png',
      staticPathTemplate: 'gs://sg360/230426/Sierras/sle_green_{nnn}.png'
    },
    rose: {
      pathTemplate: 'gs://sg360/220520/Sierras/sle/sle_rose_{nnn}.png',
      staticPathTemplate: 'gs://sg360/230426/Sierras/sle_rose_{nnn}.png'
    },
    silverblue: {
      pathTemplate: 'gs://sg360/220520/Sierras/sle/sle_silverblue_{nnn}.png',
      staticPathTemplate: 'gs://sg360/230426/Sierras/sle_silverblue_{nnn}.png'
    },
    smoke: {
      pathTemplate: 'gs://sg360/220520/Sierras/sle/sle_smoke_{nnn}.png',
      staticPathTemplate: 'gs://sg360/230426/Sierras/sle_smoke_{nnn}.png'
    },
    clear: {
      pathTemplate: 'gs://sg360/270624/Sierras/sle/sle_clear_{nnn}.png',
      staticPathTemplate: 'gs://sg360/270624/Sierras/sle/sle_clear_{nnn}.png'
    }
  }
}

export const reflection = {
  id: 'reflections',
  isReflection: true,
  name: 'Reflections',
  imgproxySettings,
  mobileImgproxySettings,
  options: {
    none: {
      disabled: true
    },
    r5pc: {
      pathTemplate: 'gs://sg360/220520/Sierras/sle Cloud Overlay/5/Sierras_Cloud Overlay 5_{nnn}.png'
    },
    r10pc: {
      pathTemplate: 'gs://sg360/220520/Sierras/sle Cloud Overlay/10/Sierras_Cloud Overlay 10_{nnn}.png'
    }
  }
}

export const engraving = {
  id: 'engraving',
  name: 'Engraving',
  imgproxySettings,
  mobileImgproxySettings,
  options: {
    eN: {
      pathTemplate: 'gs://sg360/220520/Sierras/slg/slg_N_{nnn}.png'
    },
    eP: {
      pathTemplate: 'gs://sg360/220520/Sierras/slg/slg_P_{nnn}.png'
    },
    e8: {
      pathTemplate: 'gs://sg360/220520/Sierras/slg/slg_8_{nnn}.png'
    },
    e8P: {
      pathTemplate: 'gs://sg360/220520/Sierras/slg/slg_8P_{nnn}.png'
    },
    none: {
      disabled: true
    }
  }
}

export default {
  layers: [
    background,
    frame,
    icons,
    lenses,
    engraving,
    reflection
  ],
  resolveOptions,
  resolveOptionsFromSKU
}